import { useAppDispatch } from 'app/hooks';
import { IToast, ToastStatus } from 'interfaces/toasts';
import {
  ButtonField,
  Notification,
  NotificationActions,
} from '@wk/components-react16';
import { removeToast } from 'app/toast/toastSlice';
import {
  capitalizeFirstLetter,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useState, useRef } from 'react';
import {
  useDeleteWorkflowMutation,
  useLazyGetWorkflowsQuery,
} from 'api/workflows';
import { searchFilter } from 'app/runOrchestrations/RunOrchestrationsSlice';

export interface IDeleteOrchestrationToast {
  item: IToast;
}

export const DeleteOrchestrationToast = ({
  item,
}: IDeleteOrchestrationToast) => {
  const dispatch = useAppDispatch();
  const [openStatus, setOpenStatus] = useState(true);
  const [deleteWorkflow, { isLoading }] = useDeleteWorkflowMutation();
  const container = useRef<HTMLDivElement>(null);
  const isFirstClick = useRef(false);
  const isProduct = item.additionalData?.productId != null;
  const PRODUCT_VARIANT = 'product variant';
  const ORCHESTRATION = 'orchestration';

  const handleDelete = async () => {
    if (item?.additionalData?.id) {
      const response: any = await deleteWorkflow({
        id: item.additionalData?.id,
        productId: item.additionalData?.productId ?? undefined,
      });
      let toastStatus: ToastStatus = 'error';
      if (response.error) {
        let errorText = response.error?.data ?? 'Error';
        if (errorText.includes('running')) {
          errorText =
            'Orchestration cannot be deleted as associated orchestration instances are in progress.';
          toastStatus = 'info';
        }
        showToastAndClose(
          toastStatus,
          'informationToast',
          (...args) => {},
          dispatch,
          { text: errorText }
        );
      } else {
        toastStatus = 'info';
        showToastAndClose(
          toastStatus,
          'informationToast',
          (...args) => {},
          dispatch,
          {
            text: `Your ${
              isProduct ? PRODUCT_VARIANT : ORCHESTRATION
            } and all associated pending orchestration instances have been deleted`,
          }
        );
      }
      setOpenStatus(false);
    }
  };
  const handleCancel = () => {
    setOpenStatus(false);
  };

  const handleClickOutside = (event: any) => {
    if (
      openStatus &&
      container.current &&
      !container.current.contains(event.target) &&
      isFirstClick.current
    ) {
      setOpenStatus(false);
    } else {
      isFirstClick.current = true;
    }
  };

  document.addEventListener('click', handleClickOutside);

  return (
    <CsbErrorBoundary>
      <div data-testid={`DeleteOrchestrationToast-${item.id}`} ref={container}>
        <Notification
          controlMode="uncontrolled"
          isOpen={openStatus}
          mode="toast"
          type="warning"
          autoCloseTimeout={0}
          hasCloseButton
          heading={capitalizeFirstLetter('warning')}
          onCloseEnd={() => dispatch(removeToast(item.id))}
          key={item.id}
        >
          <span slot="notificationContent">
            <span>
              {`Are you sure you want to delete this `}
              {isProduct ? PRODUCT_VARIANT : ORCHESTRATION}?
            </span>
            <p style={{ marginTop: '10px' }}>
              {`All pending orchestration instances associated with this `}
              {isProduct ? PRODUCT_VARIANT : ORCHESTRATION} will also be deleted
            </p>
          </span>
          <NotificationActions slot="notificationActions">
            <ButtonField mode={'text'} size={'small'}>
              <button
                type="button"
                onClick={handleDelete}
                disabled={isLoading}
                data-testid={'DeleteBtn'}
              >
                Delete
              </button>
            </ButtonField>
            <ButtonField mode={'text'} size={'small'}>
              <button
                type="button"
                onClick={handleCancel}
                disabled={isLoading}
                data-testid={'CancelBtn'}
              >
                Cancel
              </button>
            </ButtonField>
          </NotificationActions>
        </Notification>
      </div>
    </CsbErrorBoundary>
  );
};
