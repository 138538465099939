import { IOrchestrationFullStep } from 'interfaces/orchestrationDiagram/orchestration-step';

export interface IWorkflow {
  id: number;
  name: string;
  isProduct: boolean;
  status?: RUN_ORCHESTRARIONS_STATUS;
  description?: string;
  products?: {
    name: string;
    businessProduct?: any;
    cronTimezone?: any;
    cronTrigger?: any;
    eventParametersMapping?: any;
    ftpDropzone?: any;
    notifyEmails?: any;
    params?: any[];
    productId?: number;
    reportingIndex?: any;
    s3Dropzone?: any;
    sftpDropzone?: any;
    sftpPrivateKey?: any;
    unpackInput?: boolean;
    workflowId?: number;
  }[];
  worker?: string;
  throttleRate?: string;
  steps?: IOrchestrationFullStep[];
  supportsPrioritization?: boolean;
  hasDynamicParameters?: boolean;
}

export interface IWorkflowProduct extends IWorkflow {
  parentName: string;
  params: { paramId: number; value: string }[];
  parentId: number;
  productId: number;
  businessProduct: string;
  cronTimezone: string;
  cronTrigger: string;
  eventParametersMapping: string;
  notifyEmails: string;
  reportingIndex: string;
  s3Dropzone: string;
  ftpDropzone: string;
  sftpDropzone: string;
  sftpPrivateKey: string;
  unpackInput: boolean;
}

export enum RUN_ORCHESTRARIONS_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export interface IDeleteWorkflow {
  id: string | number;
  productId?: string | number;
}
