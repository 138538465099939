export enum MODAL_NAMES {
  ERROR_LOG_MODAL = 'modals/errorLogModal',
  SERVICE_NOW_MODAL = 'modals/serviceNowModal',
  CONFIGURE_AND_RUN = 'modals/configureAndRun',
  CREATE_PRODUCT = 'modals/createProductModal',
  EMPTY = '',
  EDIT_PRODUCT = 'modals/editProductModal',
}

export const MODAL_FORMS_LIST: { [key in MODAL_NAMES]?: any } = {
  [MODAL_NAMES.ERROR_LOG_MODAL]: () =>
    import('components/Modals/body/errorLogModal/ErrorLogModal'),
  [MODAL_NAMES.SERVICE_NOW_MODAL]: () =>
    import('components/Modals/body/ServiceNowModal/ServiceNowModal'),
  [MODAL_NAMES.CONFIGURE_AND_RUN]: () =>
    import('components/Modals/body/ConfigureAndRunModal/ConfigureAndRunModal'),
  [MODAL_NAMES.CREATE_PRODUCT]: () =>
    import('components/Modals/body/CreateProductModal/CreateProductModal'),
  [MODAL_NAMES.EDIT_PRODUCT]: () =>
    import('components/Modals/body/CreateProductModal/CreateProductModal'),
};

export const MODAL_FOOTER_LIST: { [key: string]: any } = {
  'cancel-reset-apply': () =>
    import(
      'components/Modals/footers/CancelResetApplyFooter/CancelResetApplyFooter'
    ),
  'cancel-download': () =>
    import(
      'components/Modals/footers/CancelDownloadFooter/CancelDownloadFooter'
    ),
  'cancel-submit': () =>
    import('components/Modals/footers/CancelSubmitFooter/CancelSubmitFooter'),
  'cancel-next': () =>
    import('components/Modals/footers/CancelNextSubmit/CancelNextSubmitFooter'),
};
