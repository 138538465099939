import { fetchEventSource } from '@microsoft/fetch-event-source';
import {
  addJob,
  updateCounters,
  updateJobStatus,
  updateStepStatus,
} from 'app/dashboard/DashboardSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  updateCurrentJobStatus,
  updateCurrentJobSteps,
} from 'app/propertiesPanel/PropertiesPanelSlice';
import { PAGE, SSE_EVENTS, SSE_STATUS } from 'utils/common-constants';
import { useEffect, useRef } from 'react';
import {
  selectConnectionStatus,
  selectUserId,
  setConnectionStatus,
} from 'app/auth/AuthSlice';
import {
  addNewWorkflow,
  deleteWorkflow,
  updateWorkflow,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import { safeParse } from 'utils/commonFunctions/CommonFunctions';
import { signal } from '@preact/signals-react';

export const uuidSignal = signal(Math.floor(Math.random() * 1000000) + 1);
export const useServerSideEvents = () => {
  const connectionStatus = useAppSelector(selectConnectionStatus);
  const connectionStatusRef = useRef<SSE_STATUS | undefined>(connectionStatus);
  const userId = useAppSelector(selectUserId);
  const userIdRef = useRef<number>(userId);
  let abortController: AbortController;
  const dispatch = useAppDispatch();
  const currentConnection = useRef<any>();

  useEffect(() => {
    userIdRef.current = userId;
  }, [userId]);

  useEffect(() => {
    if (currentConnection.current === undefined) {
      currentConnection.current = SSE();
    }
    return () => {
      abortController && abortController.abort();
      currentConnection.current = undefined;
      console.log('Cleaning up connection');
    };
  }, []);

  async function SSE() {
    abortController = new AbortController();
    let hasEventTimeout: NodeJS.Timeout;
    if (connectionStatusRef.current === SSE_STATUS.ERROR) {
      dispatch(setConnectionStatus(SSE_STATUS.RESTORED));
      connectionStatusRef.current = SSE_STATUS.RESTORED;
    } else if (connectionStatusRef.current === SSE_STATUS.RESTORED) {
      connectionStatusRef.current = SSE_STATUS.SUCCESS;
      dispatch(setConnectionStatus(SSE_STATUS.SUCCESS));
    }
    try {
      await fetchEventSource(`${window.location.origin.toString()}/csb/sse`, {
        method: 'GET',
        headers: {
          Accept: 'text/event-stream',
        },
        signal: abortController.signal,
        openWhenHidden: true,
        async onopen(res) {
          if (res.ok && res.status === 200) {
            if (connectionStatusRef.current === SSE_STATUS.RESTORED) {
              // set timeout to reconnect in 15 mins, to switch csb instance
              console.log('SSE Connection restored');
              setTimeout(() => {
                console.log('Refreshing SSE connection');
                clearTimeout(hasEventTimeout);
                abortController.abort();
                currentConnection.current = SSE();
              }, 90000);
            }
            console.log('SSE Connection made');
          } else if (
            res.status >= 400 &&
            res.status < 500 &&
            res.status !== 429
          ) {
            console.log('Client side error');
            abortController.abort();
            console.log(res);
          } else {
            console.log('Unkown error');
            abortController.abort();
            setTimeout(() => {
              abortController && abortController.abort();
              currentConnection.current = SSE();
            }, 120000);
          }
        },
        onmessage(event) {
          clearTimeout(hasEventTimeout);
          if (connectionStatusRef.current !== SSE_STATUS.SUCCESS) {
            dispatch(setConnectionStatus(SSE_STATUS.SUCCESS));
            connectionStatusRef.current = SSE_STATUS.SUCCESS;
          }
          const url = new URL(window.location.href);
          const path = url.pathname;
          const page = getPage(path);
          const eventTypes = filterEventsByPage(page);
          console.log(event);

          eventTypes.forEach((type) => {
            if (type !== event.event || event.data == null) {
              return;
            }
            const parsedData = safeParse(event.data);
            switch (type) {
              case SSE_EVENTS.JOB_CREATED:
                dispatch(
                  addJob({ job: parsedData, userId: userIdRef.current })
                );
                break;
              case SSE_EVENTS.JOB_COUNTERS_UPDATE:
                dispatch(updateCounters(parsedData));
                break;
              case SSE_EVENTS.JOB_STATUS_UPDATE:
                page === PAGE.ORCHESTRATION_DETAILS
                  ? dispatch(updateCurrentJobStatus(parsedData))
                  : dispatch(updateJobStatus(parsedData));
                break;
              case SSE_EVENTS.JOB_STEP_UPDATE:
                page === PAGE.ORCHESTRATION_DETAILS
                  ? dispatch(updateCurrentJobSteps(parsedData))
                  : dispatch(updateStepStatus(parsedData));
                break;
              case SSE_EVENTS.ORCHESTRATION_CREATED:
                dispatch(addNewWorkflow(parsedData));
                break;
              case SSE_EVENTS.ORCHESTRATION_DELETED:
                dispatch(deleteWorkflow(parsedData));
                break;
              case SSE_EVENTS.ORCHESTRATION_UPDATED:
                dispatch(updateWorkflow(parsedData));
                break;
              default:
                break;
            }
          });
          hasEventTimeout = restartIfNoMessage(
            abortController,
            currentConnection,
            SSE,
            dispatch,
            connectionStatusRef
          );
        },
        onclose() {
          // try to reconnect
          dispatch(setConnectionStatus(SSE_STATUS.ERROR));
          connectionStatusRef.current = SSE_STATUS.ERROR;
          abortController && abortController.abort();
          throw new Error('Connection closed by the server');
        },
        onerror(err) {
          // try to reconnec
          dispatch(setConnectionStatus(SSE_STATUS.ERROR));
          connectionStatusRef.current = SSE_STATUS.ERROR;
          abortController && abortController.abort();
          console.log(err);
          throw new Error('There was an error from server');
        },
      });
    } catch (error) {
      console.log('SSE Failed to connect, trying again');
      abortController && abortController.abort();
      currentConnection.current = SSE();
    }
  }
};

const getPage = (path: string): PAGE => {
  if (path.includes('/dashboard/orchestration/')) {
    return PAGE.ORCHESTRATION_DETAILS;
  } else if (path === '/dashboard') {
    return PAGE.ORCHESTRATION_STATUS;
  } else if (path === '/run-orchestrations') {
    return PAGE.RUN_ORCHESTRATION;
  } else {
    return PAGE.ORCHESTRATION_STATUS;
  }
};

const filterEventsByPage = (page: PAGE): SSE_EVENTS[] => {
  if (page === PAGE.ORCHESTRATION_STATUS) {
    return [
      SSE_EVENTS.JOB_CREATED,
      SSE_EVENTS.JOB_STATUS_UPDATE,
      SSE_EVENTS.JOB_STEP_UPDATE,
      SSE_EVENTS.JOB_COUNTERS_UPDATE,
    ];
  }
  if (page === PAGE.RUN_ORCHESTRATION) {
    return [
      SSE_EVENTS.ORCHESTRATION_CREATED,
      SSE_EVENTS.ORCHESTRATION_DELETED,
      SSE_EVENTS.ORCHESTRATION_UPDATED,
    ];
  }
  if (page === PAGE.ORCHESTRATION_DETAILS) {
    return [SSE_EVENTS.JOB_STEP_UPDATE, SSE_EVENTS.JOB_STATUS_UPDATE];
  }
  return [];
};

const restartIfNoMessage = (
  abortController: any,
  currentConnection: any,
  SSE: any,
  dispatch: any,
  connectionStatusRef: any
) =>
  setTimeout(() => {
    dispatch(setConnectionStatus(SSE_STATUS.ERROR));
    connectionStatusRef.current = SSE_STATUS.ERROR;
    abortController && abortController.abort();
    console.log(
      'No event received in 15 seconds (SSE connection lost). Retrying connection...'
    );
    currentConnection.current = SSE();
  }, 15000);
