import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { mapProductToTable } from 'dto/runOrchestration/productToTable';
import { IRunOrchestrationSlice } from 'interfaces/runOrchestration/run-orchestration-state.interface';
import { mapWorkflowToTable } from 'dto/runOrchestration/workflowToTable';
import {
  IWorkflowProduct,
  IWorkflow,
  RUN_ORCHESTRARIONS_STATUS,
} from 'interfaces/runOrchestration/workflow-row';
import { workflowsApi } from 'api/workflows';
import {
  safeParse,
  safeStringify,
} from 'utils/commonFunctions/CommonFunctions';
import { IServerWorkflow } from 'interfaces/runOrchestration/server-workflow';

export const SLICE_KEY = 'runOrchestrations';

const initialState: IRunOrchestrationSlice = {
  loading: false,
  workflows: [],
  notFilteredWorkflows: '[]',
  filterModel: null,
  selectedWorkflow: null,
  wizardActiveIndex: 0,
  isFilteringOnlyActive: false,
  filterQuery: null,
  updatedWorkflow: null,
};

const filterWorkflows = (
  workflows: IWorkflow[] | IWorkflowProduct[],
  searchParam: string
) => {
  let filteredWorkflows: any[] = [];
  if (workflows != null) {
    filteredWorkflows = [...workflows]
      ?.map((workflow: IWorkflow) => {
        const filteredProducts = workflow.products?.filter((product) =>
          product.name?.toLowerCase().includes(searchParam.toLowerCase())
        );
        const workflowFilteredProducts: IWorkflow = {
          ...workflow,
          products: filteredProducts,
        };
        const returnArray = [];
        if (
          workflow.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
          filteredProducts?.length
        ) {
          returnArray.push(workflowFilteredProducts);
          filteredProducts &&
            filteredProducts.forEach((product) =>
              returnArray.push(mapProductToTable(product, workflow))
            );
        }
        return returnArray.flat();
      })
      .filter(Boolean);
  }
  return filteredWorkflows.flat();
};

const findInsertIndexForNewWorkflow = (
  baseArray: IWorkflow[],
  newElement: IWorkflow
) => {
  let insertIndex = 0;
  insertIndex = baseArray?.findIndex(
    (w) => !w.isProduct && w.name.localeCompare(newElement.name) > 0
  );
  if (insertIndex === -1) {
    insertIndex = baseArray.length;
  }
  return insertIndex;
};

const runOrchestrationsSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setWizardActiveIndex: (state, action: PayloadAction<number>) => {
      return {
        ...state,
        wizardActiveIndex: state.wizardActiveIndex <= 3 ? action.payload : 3,
      };
    },
    startLoading: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    addWorkflowProducts: (state, action: PayloadAction<IWorkflow>) => {
      const orchestrationParent = action.payload;
      const parentIndex = state.workflows?.findIndex(
        (o) => o.id === orchestrationParent.id
      );
      const newProductsWorkflows: IWorkflowProduct[] =
        state.workflows[parentIndex]?.products?.map((product: any) =>
          mapProductToTable(product, orchestrationParent)
        ) ?? [];
      let newWorkflows = [...state.workflows];
      if (
        parentIndex > -1 &&
        newWorkflows?.length > 0 &&
        newProductsWorkflows?.length > 0
      ) {
        newWorkflows.splice(parentIndex + 1, 0, ...newProductsWorkflows);
      }

      return {
        ...state,
        workflows: newWorkflows,
      };
    },
    removeWorkflowProducts: (state, action: PayloadAction<any>) => {
      const { id, products } = action.payload;
      const parentIndex = state.workflows?.findIndex((o) => o.id === id);
      let newWorkflows = [...state.workflows];
      if (
        parentIndex > -1 &&
        products?.length > 0 &&
        newWorkflows[parentIndex + 1]?.isProduct &&
        newWorkflows[parentIndex + products?.length]?.isProduct
      ) {
        newWorkflows.splice(parentIndex + 1, products?.length);
      }

      return {
        ...state,
        workflows: newWorkflows,
      };
    },
    setSelectedWorkflow: (
      state,
      action: PayloadAction<IWorkflow | IWorkflowProduct | null>
    ) => {
      return {
        ...state,
        selectedWorkflow: action.payload,
      };
    },
    toggleFilteringOnlyActive: (state) => {
      const isDisplayingOnlyActive = !state.isFilteringOnlyActive;
      if (isDisplayingOnlyActive === true) {
        let filteredWorkflows: IWorkflow[] | IWorkflowProduct[] =
          state.workflows.filter(
            (item: IWorkflow | IWorkflowProduct) =>
              item.status === RUN_ORCHESTRARIONS_STATUS.ACTIVE
          );
        return {
          ...state,
          isFilteringOnlyActive: isDisplayingOnlyActive,
          workflows: filteredWorkflows,
        };
      } else {
        if (state.filterQuery != null && state.filterQuery !== '') {
          let filteredWorkflows: IWorkflow[] | IWorkflowProduct[] =
            filterWorkflows(
              safeParse(state.notFilteredWorkflows),
              state.filterQuery
            );
          return {
            ...state,
            isFilteringOnlyActive: isDisplayingOnlyActive,
            workflows: filteredWorkflows,
          };
        } else {
          return {
            ...state,
            isFilteringOnlyActive: isDisplayingOnlyActive,
            workflows: safeParse(state.notFilteredWorkflows),
          };
        }
      }
    },

    searchFilter: (state, action: PayloadAction<string | null>) => {
      const searchParams = action.payload;
      if (!searchParams) {
        return {
          ...state,
          filterQuery: searchParams,
          workflows: safeParse(state.notFilteredWorkflows),
        };
      }
      let filteredWorkflows: IWorkflow[] | IWorkflowProduct[] = filterWorkflows(
        safeParse(state.notFilteredWorkflows),
        searchParams
      );
      return {
        ...state,
        filterQuery: searchParams,
        workflows: filteredWorkflows,
      };
    },
    addNewWorkflow: (state, action: PayloadAction<IServerWorkflow>) => {
      let workflow: IWorkflow = mapWorkflowToTable(action.payload);
      workflow.products?.forEach((product) =>
        mapProductToTable(product, workflow)
      );
      if (state.filterQuery != null && state.filterQuery !== '') {
        const notFilteredWorkflows: IWorkflow[] = safeParse(
          state.notFilteredWorkflows
        );
        const updatedFilteredWorkflows = [...state.workflows];
        const updatedWorkflows = [...notFilteredWorkflows];
        let insertIndex = findInsertIndexForNewWorkflow(
          updatedFilteredWorkflows,
          workflow
        );
        updatedFilteredWorkflows.splice(insertIndex, 0, workflow);
        insertIndex = findInsertIndexForNewWorkflow(updatedWorkflows, workflow);
        updatedWorkflows.splice(insertIndex, 0, workflow);
        return {
          ...state,
          workflows: updatedFilteredWorkflows,
          notFilteredWorkflows: safeStringify(updatedWorkflows),
        };
      } else {
        const updatedWorkflows = [...state.workflows];
        let insertIndex = findInsertIndexForNewWorkflow(
          updatedWorkflows,
          workflow
        );
        updatedWorkflows.splice(insertIndex, 0, workflow);
        return {
          ...state,
          workflows: updatedWorkflows,
          notFilteredWorkflows: safeStringify(updatedWorkflows),
        };
      }
    },
    deleteWorkflow: (state, action: PayloadAction<{ workflowId: number }>) => {
      if (state.filterQuery != null && state.filterQuery !== '') {
        const notFilteredWorkflows: IWorkflow[] = safeParse(
          state.notFilteredWorkflows
        );
        const updatedWorkflows = [...notFilteredWorkflows].filter(
          (workflow) => workflow.id !== action.payload.workflowId
        );
        const updatedFilteredWorkflows = [...state.workflows].filter(
          (workflow) => workflow.id !== action.payload.workflowId
        );
        return {
          ...state,
          workflows: updatedFilteredWorkflows,
          notFilteredWorkflows: safeStringify(updatedWorkflows),
        };
      } else {
        const updatedWorkflows = [...state.workflows].filter(
          (workflow) => workflow.id !== action.payload.workflowId
        );
        return {
          ...state,
          workflows: updatedWorkflows,
          notFilteredWorkflows: safeStringify(updatedWorkflows),
        };
      }
    },
    updateWorkflow: (state, action: PayloadAction<IServerWorkflow>) => {
      let workflow: IWorkflow = mapWorkflowToTable(action.payload);
      workflow.products?.forEach((product) =>
        mapProductToTable(product, workflow)
      );
      let addedProducts: any[] = [];
      if (state.filterQuery != null && state.filterQuery !== '') {
        const notFilteredWorkflows: IWorkflow[] = safeParse(
          state.notFilteredWorkflows
        );

        const updatedWorkflows = [...notFilteredWorkflows]
          ?.map((w) => {
            if (w.id === workflow.id) {
              return workflow;
            } else if (
              w.isProduct &&
              (w as IWorkflowProduct).parentId === workflow.id
            ) {
              if (addedProducts.length === 0) {
                addedProducts = [...(workflow.products || [])];
                return workflow.products?.map((product) =>
                  mapProductToTable(product, workflow)
                );
              } else {
                return null as any;
              }
            } else {
              return w;
            }
          })
          .flat()
          .filter((x) => x !== null && x !== undefined);
        addedProducts = [];
        const updatedFilteredWorkflows = [...state.workflows]
          ?.map((w) => {
            if (w.id === workflow.id) {
              return workflow;
            } else if (
              w.isProduct &&
              (w as IWorkflowProduct).parentId === workflow.id
            ) {
              if (addedProducts.length === 0) {
                addedProducts = [...(workflow.products || [])];
                return workflow.products?.map((product) =>
                  mapProductToTable(product, workflow)
                );
              } else {
                return null as any;
              }
            } else {
              return w;
            }
          })
          .flat()
          .filter((x) => x !== null && x !== undefined);
        return {
          ...state,
          workflows: updatedFilteredWorkflows,
          notFilteredWorkflows: safeStringify(updatedWorkflows),
          updatedWorkflow: safeStringify(action.payload),
        };
      } else {
        const updatedWorkflows = [...state.workflows]
          ?.map((w) => {
            if (w.id === workflow.id) {
              return workflow;
            } else if (
              w.isProduct &&
              (w as IWorkflowProduct).parentId === workflow.id
            ) {
              if (addedProducts.length === 0) {
                addedProducts = [...(workflow.products || [])];
                return workflow.products?.map((product) =>
                  mapProductToTable(product, workflow)
                );
              } else {
                return null as any;
              }
            } else {
              return w;
            }
          })
          .flat()
          .filter((x) => x !== null && x !== undefined);
        return {
          ...state,
          workflows: updatedWorkflows,
          notFilteredWorkflows: safeStringify(updatedWorkflows),
          updatedWorkflow: safeStringify(action.payload),
        };
      }
    },
    resetUpdatedWorkflow: (state) => {
      return {
        ...state,
        updatedWorkflow: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      workflowsApi.endpoints.getWorkflows.matchPending,
      (state) => {
        return {
          ...state,
          loading: true,
        };
      }
    );

    builder.addMatcher(
      workflowsApi.endpoints.getWorkflows.matchFulfilled,
      (state, action: PayloadAction<IServerWorkflow[]>) => {
        if (action.payload?.length > 0) {
          let workflows: IWorkflow[] = action.payload?.map(
            (workflow: IServerWorkflow) => mapWorkflowToTable(workflow)
          );
          workflows.forEach((workflow: IWorkflow) =>
            workflow.products?.forEach((product) =>
              mapProductToTable(product, workflow)
            )
          );

          return {
            ...state,
            loading: false,
            workflows: workflows,
            notFilteredWorkflows: safeStringify(workflows),
          };
        }
      }
    );

    builder.addMatcher(
      workflowsApi.endpoints.getWorkflows.matchRejected,
      (state) => {
        return {
          ...state,
          loading: false,
        };
      }
    );
  },
});

export const selectIsFileringOnlyActive = (state: RootState) =>
  state.runOrchestrations.isFilteringOnlyActive;

export const selectWorkflows = (state: RootState) =>
  state.runOrchestrations.workflows;
export const selectRunOrchestrationLoading = (state: RootState) =>
  state.runOrchestrations.loading;

export const selectSelectedWorkflow = (state: RootState) =>
  state.runOrchestrations.selectedWorkflow;

export const selectWizardActiveIndex = (state: RootState) =>
  state.runOrchestrations.wizardActiveIndex;

export const selectFilterQuery = (state: RootState) =>
  state.runOrchestrations.filterQuery;

export const selectUpdatedWorkflow = (state: RootState) =>
  state.runOrchestrations.updatedWorkflow;

export type SelectIsFileringOnlyActiveType = ReturnType<
  typeof selectIsFileringOnlyActive
>;
export type SelectWorkflowsType = ReturnType<typeof selectWorkflows>;
export type SelectSelectedWorkflowType = ReturnType<
  typeof selectSelectedWorkflow
>;

export const {
  setWizardActiveIndex,
  addWorkflowProducts,
  removeWorkflowProducts,
  startLoading,
  setSelectedWorkflow,
  toggleFilteringOnlyActive,
  searchFilter,
  addNewWorkflow,
  deleteWorkflow,
  updateWorkflow,
  resetUpdatedWorkflow,
} = runOrchestrationsSlice.actions;

export default runOrchestrationsSlice.reducer;
