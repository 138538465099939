import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authApi } from 'api/authApi';
import { IUser } from '../../interfaces/user.interface';
import { SSE_STATUS } from 'utils/common-constants';

interface IAuthState {
  isLoggedIn: boolean;
  user: IUser | null;
  connectionStatus: SSE_STATUS | undefined;
}

export const SLICE_KEY = 'auth';

const initialState: IAuthState = {
  isLoggedIn: false,
  user: null,
  connectionStatus: undefined,
};

const authSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setConnectionStatus: (state, { payload }: PayloadAction<SSE_STATUS>) => {
      return {
        ...state,
        connectionStatus: payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.postLogin.matchFulfilled,
      (state, { payload }: PayloadAction<IUser | any>) => {
        if (payload?.redirect) {
          window.location.assign(payload.redirect);
        } else {
          state.isLoggedIn = true;
          state.user = payload.user;
        }
      }
    );

    builder.addMatcher(authApi.endpoints.postLogin.matchRejected, (state) => {
      const isDev = process.env.NODE_ENV === 'development';
      return {
        ...state,
        isLoggedIn: isDev,
        user: {
          id: '1',
          email: '',
          userRole: {
            permissions: {
              ORCHESTRATION_STOP: 'Enable',
            },
          },
        },
      };
    });

    builder.addMatcher(
      authApi.endpoints.postLogout.matchFulfilled,
      (state, { payload }: any) => {
        state.isLoggedIn = false;
        state.user = null;
        if (payload?.redirect) {
          window.location.assign(payload.redirect);
        }
      }
    );
  },
});
export const selectUserPermissions = (state: any) =>
  state.auth.user.userRole.permissions;
export const selectUserRole = (state: any) =>
  state.auth.user?.userRole?.name ?? '-';
export const selectUserEmail = (state: any) => state.auth.user?.email ?? '-';
export const selectConnectionStatus = (state: any) =>
  state.auth.connectionStatus;
export const selectUserId = (state: any) => state.auth.user?.id;

export const { setConnectionStatus } = authSlice.actions;

export default authSlice.reducer;
