import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import style from './CellWorkflowName.module.scss';
import { RadioField } from '@wk/components-react16/dist/radio-field/radio-field';
import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import {
  IWorkflowProduct,
  IWorkflow,
} from 'interfaces/runOrchestration/workflow-row';
import {
  removeWorkflowProducts,
  addWorkflowProducts,
  setSelectedWorkflow,
  selectSelectedWorkflow,
  selectFilterQuery,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import { Tooltip } from '@wk/components-react16/dist/tooltip/tooltip';
import { TooltipBody } from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectConnectionStatus } from 'app/auth/AuthSlice';
import { SSE_STATUS } from 'utils/common-constants';

interface ICellWorkflowName {
  data: Partial<IWorkflowProduct>;
}

const CellWorkflowName = (props: ICellWorkflowName) => {
  const rowData: Partial<IWorkflowProduct> = props.data;
  const dispatch = useAppDispatch();
  const isFiltering = useAppSelector(selectFilterQuery);
  const [isChevronOpen, setIsChevronOpen] = useState<any>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState<boolean>(false);
  const products = rowData.products ?? [];
  const name = rowData.name;
  const cellRef = useRef<MutableRefObject<any> | any>();
  const selectedWorkflow = useAppSelector(selectSelectedWorkflow);
  const childsAlreadyAdded = useRef(false);
  const connectionStatusState = useAppSelector(selectConnectionStatus);

  useEffect(() => {
    if (isChevronOpen === true && !childsAlreadyAdded.current) {
      dispatch(addWorkflowProducts(rowData as IWorkflow));
    } else if (isChevronOpen === false) {
      childsAlreadyAdded.current = false;
      dispatch(removeWorkflowProducts({ id: rowData.id, products }));
    }
  }, [isChevronOpen]);

  useEffect(() => {
    if (isFiltering != null && isFiltering !== '') {
      if (isChevronOpen) {
        childsAlreadyAdded.current = false;
      } else {
        childsAlreadyAdded.current = true;
      }
      setIsChevronOpen(true);
    } else {
      setIsChevronOpen(false);
      childsAlreadyAdded.current = false;
    }
  }, [isFiltering]);

  useEffect(() => {
    if (selectedWorkflow?.id === rowData.id) {
      cellRef.current?.parentElement?.parentElement?.classList.add(
        'csb-focus-row'
      );
    } else {
      cellRef.current?.parentElement?.parentElement?.classList.remove(
        'csb-focus-row'
      );
    }
  }, [selectedWorkflow]);

  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      setIsChevronOpen(false);
    }
  }, [connectionStatusState]);

  const selectOrchestration = () => {
    if (selectedWorkflow?.id !== rowData.id) {
      dispatch(setSelectedWorkflow(rowData as any));
    }
  };
  return (
    <CsbErrorBoundary>
      <div
        className={style['cell-container']}
        data-testid="CellWorkflowName"
        id={`name-${rowData.id}`}
        onMouseEnter={() => setIsTooltipOpen(true)}
        onMouseLeave={() => setIsTooltipOpen(false)}
        ref={cellRef}
        onClick={() =>
          setIsChevronOpen((isChevronOpen: any) =>
            isChevronOpen === null ? true : !isChevronOpen
          )
        }
      >
        {products?.length > 0 && (
          <div className={style['cell-chevron']}>
            <div>
              {isChevronOpen ? (
                <Iconsvg name="chevron-down"></Iconsvg>
              ) : (
                <Iconsvg name="chevron-right"></Iconsvg>
              )}
            </div>
          </div>
        )}
        <div
          className={
            rowData.isProduct
              ? style['cell-product']
              : style['cell-orchestration']
          }
        >
          <RadioField>
            <input
              type="radio"
              name="cellNameRadioBtn"
              onChange={() => selectOrchestration()}
              checked={selectedWorkflow?.id === rowData.id}
            />
          </RadioField>
        </div>
        <span>{name}</span>
        {name && name?.length > 54 && (
          <Tooltip
            position="top"
            targetSelector={`#name-${rowData.id}`}
            id={`name-${rowData.id}-tooltip`}
            controlMode="controlled"
            isOpen={isTooltipOpen}
          >
            <TooltipBody slot="tooltipBody">
              <span data-testid="CellStatusTooltip">
                <span id={`name-${rowData.id}`}>{name}</span>
              </span>
            </TooltipBody>
          </Tooltip>
        )}
      </div>
    </CsbErrorBoundary>
  );
};

export default CellWorkflowName;
