import { MutableRefObject, useEffect, useRef } from 'react';
import { debounce } from '@wk/components-react16';

export const useInfiniteScroll = (
  loadDataFunction: () => Promise<void>,
  isAbleToLoadNewPage: MutableRefObject<boolean>,
  isLoadingNewPage: MutableRefObject<boolean>,
  INFINITY_SCROLL_TRIGGER: number
) => {
  const containerRef = useRef(null);
  const SCROLL_SELECTOR = '.wk-advanced-table';
  useEffect(() => {
    const debouncedLoadDataFunction = debounce(async () => {
      loadDataFunction();
    }, 300);

    const handleScroll = async () => {
      const scrollableElement = document.querySelector(SCROLL_SELECTOR);
      if (scrollableElement) {
        const { scrollTop, scrollHeight, clientHeight } = scrollableElement;
        const bottom =
          scrollHeight - scrollTop - clientHeight < INFINITY_SCROLL_TRIGGER;
        if (
          bottom &&
          !isLoadingNewPage.current &&
          isAbleToLoadNewPage.current
        ) {
          isLoadingNewPage.current = true;
          debouncedLoadDataFunction();
        }
      }
    };

    const timeoutId = setTimeout(() => {
      if (containerRef.current != null) {
        const scrollableElement = document.querySelector(SCROLL_SELECTOR);
        if (scrollableElement) {
          scrollableElement.addEventListener('scroll', handleScroll);
          return () =>
            scrollableElement.removeEventListener('scroll', handleScroll);
        }
      }
      return () =>
        document
          .querySelector(SCROLL_SELECTOR)
          ?.removeEventListener('scroll', handleScroll);
    }, 100);

    return () => {
      clearTimeout(timeoutId);
      document
        .querySelector(SCROLL_SELECTOR)
        ?.removeEventListener('scroll', handleScroll);
    };
  }, [
    loadDataFunction,
    isAbleToLoadNewPage,
    isLoadingNewPage,
    INFINITY_SCROLL_TRIGGER,
  ]);

  return containerRef;
};
