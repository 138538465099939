import { IOrchestrationFullStep } from 'interfaces/orchestrationDiagram/orchestration-step';
import {
  IWorkflowProduct,
  IWorkflow,
} from 'interfaces/runOrchestration/workflow-row';

export const mapProductToTable = (
  product: any,
  orchestrationParent: IWorkflow
): IWorkflowProduct => {
  return {
    id: product.productId + (Math.floor(Math.random() * 1000000) + 1), // not the real id, only used for render proposes
    parentName: orchestrationParent.name,
    name: product.name,
    isProduct: true,
    description: product.description,
    supportsPrioritization: orchestrationParent.supportsPrioritization,
    params: product.params,
    hasDynamicParameters: orchestrationParent.hasDynamicParameters,
    status: orchestrationParent.status,
    parentId: orchestrationParent.id,
    steps: syncStepParamsValues(orchestrationParent.steps, product.params),
    productId: product.productId, // This is the real idk
    businessProduct: product.businessProduct,
    cronTimezone: product.cronTimezone,
    cronTrigger: product.cronTrigger,
    eventParametersMapping: product.eventParametersMapping,
    notifyEmails: product.notifyEmails,
    reportingIndex: product.reportingIndex,
    s3Dropzone: product.s3Dropzone,
    ftpDropzone: product.ftpDropzone,
    sftpDropzone: product.sftpDropzone,
    sftpPrivateKey: product.sftpPrivateKey,
    unpackInput: product.unpackInput,
  };
};

export const syncStepParamsValues = (
  steps: IOrchestrationFullStep[] | undefined,
  params: IWorkflowProduct['params']
) => {
  if (!steps) {
    return undefined;
  }
  const newSteps = JSON.parse(JSON.stringify(steps));
  newSteps?.forEach((step: IOrchestrationFullStep) =>
    step?.paramGroups?.forEach((paramGroup) =>
      paramGroup?.parameters?.forEach((parameter) => {
        const matchingParam = params.find(
          (param) => param.paramId === parameter.paramId
        );
        if (matchingParam) {
          parameter.value = matchingParam.value;
        }
      })
    )
  );
  return newSteps;
};
