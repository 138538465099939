import { useLazyGetWorkflowsQuery } from 'api/workflows';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import style from './RunOrchestrations.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';

import {
  RUN_ORCHESTRATIONS_TABLE_COLUMNS,
  VIEW_OPTIONS,
} from './RunOrchestrations.constants';
import {
  searchFilter,
  selectWorkflows,
  setSelectedWorkflow,
  startLoading,
} from 'app/runOrchestrations/RunOrchestrationsSlice';

import {
  IWorkflowProduct,
  IWorkflow,
} from 'interfaces/runOrchestration/workflow-row';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import { RunOrchestrationBanner } from 'components/RunOrchestrationBanner/RunOrchestrationBanner';
import { selectConnectionStatus, selectUserRole } from 'app/auth/AuthSlice';
import { SSE_STATUS, USERS_ROLES } from 'utils/common-constants';
import RunOrchestrationTable from 'components/TableContainer/RunOrchestrationTable/RunOrchestrationTable';
import { SplitView } from '../../components/RunOrchestrationsViews/SplitView/SplitView';
import { ColumnView } from 'components/RunOrchestrationsViews/ColumnView/ColumnView';

const RunOrchestrations = () => {
  const dispatch = useAppDispatch();
  const workflowsState: IWorkflow[] | IWorkflowProduct[] =
    useAppSelector(selectWorkflows);
  const userRole = useAppSelector(selectUserRole);
  const [loadWorkflows] = useLazyGetWorkflowsQuery();
  const [tableColumns, setTableColumns] = useState<any>(
    RUN_ORCHESTRATIONS_TABLE_COLUMNS
  );
  const [currentView, setCurrentView] = useState(VIEW_OPTIONS.LIST_VIEW);
  const connectionStatusState = useAppSelector(selectConnectionStatus);

  const getInfo = useCallback(async () => {
    await loadWorkflows(null);
  }, []);

  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      getInfo();
      dispatch(searchFilter(null));
    }
    return () => {
      dispatch(searchFilter(null));
    };
  }, [connectionStatusState]);

  useEffect(() => {
    if (currentView === VIEW_OPTIONS.LIST_VIEW) {
      setTableColumns(sliceTableBasedOnUserRole(userRole));
    }
    if (currentView === VIEW_OPTIONS.SPLIT_VIEW) {
      const table = sliceTableBasedOnUserRole(userRole);
      if (
        !userRole.includes(USERS_ROLES.DEVELOPER) &&
        !userRole.includes(USERS_ROLES.ADMIN)
      ) {
        const updatedTableColumns = [...table.slice(0, 2)];
        setTableColumns(updatedTableColumns);
      } else {
        const updatedTableColumns = [...table.slice(0, 2), ...table.slice(-1)];
        setTableColumns(updatedTableColumns);
      }
    }
    if (currentView === VIEW_OPTIONS.COLUMN_VIEW) {
      const table = sliceTableBasedOnUserRole(userRole);
      let updatedTableColumns;
      if (
        !userRole.includes(USERS_ROLES.DEVELOPER) &&
        !userRole.includes(USERS_ROLES.ADMIN)
      ) {
        updatedTableColumns = [...table.slice(0, 1), ...table.slice(-1)];
        setTableColumns(updatedTableColumns);
      } else {
        updatedTableColumns = [...table.slice(0, 1), ...table.slice(-2)];
        setTableColumns(updatedTableColumns);
      }
      if (updatedTableColumns.length > 1) {
        updatedTableColumns[1] = {
          ...updatedTableColumns[1],
          maxWidth: undefined,
        };
      }
    }
  }, [currentView]);

  useEffect(() => {
    dispatch(startLoading());
    getInfo();
    return () => {
      dispatch(setSelectedWorkflow(null));
    };
  }, [getInfo]);

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.id;
    };
  }, []);

  return (
    <div
      data-testid="RunOrchestrations"
      className={style['run-orchestrations']}
    >
      <section className={style['run-orchestrations-banner']}>
        <CsbErrorBoundary>
          <RunOrchestrationBanner setCurrentView={setCurrentView} />
        </CsbErrorBoundary>
      </section>

      <section className={style['run-orchestrations-div-table']}>
        {currentView === VIEW_OPTIONS.LIST_VIEW && (
          <RunOrchestrationTable
            items={workflowsState ?? []}
            columns={tableColumns}
            onGridReadyAdditionalMethods={[]}
            additionalData={{ getRowId }}
            page={PAGE_OPTIONS.runOrchestrations}
            additionalStyles={''}
          />
        )}
        {currentView === VIEW_OPTIONS.SPLIT_VIEW && (
          <SplitView
            workflowsState={workflowsState}
            getRowId={getRowId}
            tableColumns={tableColumns}
          />
        )}
        {currentView === VIEW_OPTIONS.COLUMN_VIEW && (
          <ColumnView
            workflowsState={workflowsState}
            getRowId={getRowId}
            tableColumns={tableColumns}
          />
        )}
        <div className={style['run-orchestrations-bottom-border']}></div>
      </section>
    </div>
  );
};

export default RunOrchestrations;

const sliceTableBasedOnUserRole = (userRole: any) => {
  if (
    !userRole.includes(USERS_ROLES.DEVELOPER) &&
    !userRole.includes(USERS_ROLES.ADMIN)
  ) {
    return RUN_ORCHESTRATIONS_TABLE_COLUMNS.slice(0, 5);
  }
  return RUN_ORCHESTRATIONS_TABLE_COLUMNS;
};
