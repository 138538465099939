import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  DropdownMenuItem,
  Iconsvg,
  Tooltip,
  TooltipBody,
} from '@wk/components-react16';
import { usePostDownloadLogMutation } from 'api/orchestrationApi';
import { useAppDispatch } from 'app/hooks';
import { openModal } from 'app/modal/ModalSlice';
import { ORCHESTRATION_STATUSES } from 'utils/common-constants';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import { downloadLog } from 'utils/commonFunctions/CommonFunctions';
import { useState } from 'react';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { MODAL_NAMES } from 'components/Modals/modal-list';

const OrchestrationHeader = ({
  selectedOrchestration,
}: IOrchestrationRow | any) => {
  const [downloadHook] = usePostDownloadLogMutation();
  const dispatch = useAppDispatch();
  const [isErrorLogOpen, setIsErrorLogOpen] = useState<boolean>(false);
  const checkIfDisabled = (status: ORCHESTRATION_STATUSES): boolean => {
    if (
      status === ORCHESTRATION_STATUSES.FAILED ||
      status === ORCHESTRATION_STATUSES.FINISHED ||
      status === ORCHESTRATION_STATUSES.FINISHED_ERROR
    ) {
      return false;
    }
    return true;
  };

  const openErrorLogModal = () => {
    dispatch(
      openModal({
        id: '1',
        componentName: MODAL_NAMES.ERROR_LOG_MODAL,
        title: `Orchestration Instance Error Log Id: ${selectedOrchestration.id}`,
        footerName: 'cancel-download',
        display: true,
        componentProps: { data: selectedOrchestration },
        width: '1500px',
      })
    );
  };

  return (
    <CsbErrorBoundary>
      <ButtonGroup alignment={'left'} dropdownToggleSize="small16px">
        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'} size={'small'} iconPosition={'left'}>
            <div
              id="view-error-button"
              onMouseOver={() => setIsErrorLogOpen(true)}
              onMouseLeave={() => setIsErrorLogOpen(false)}
            >
              <button
                type="button"
                onClick={() => openErrorLogModal()}
                disabled={checkIfDisabled(selectedOrchestration?.status)}
                data-testid="ViewErrorLogBtn"
              >
                <Iconsvg name="visible"></Iconsvg>
                View error log
              </button>
            </div>
          </ButtonField>
          <Tooltip
            position="bottom"
            targetSelector="#view-error-button"
            id="view-tooltip"
            controlMode="controlled"
            isOpen={isErrorLogOpen}
          >
            <TooltipBody slot="tooltipBody">View error log</TooltipBody>
          </Tooltip>
        </ButtonGroupItem>

        <ButtonGroupItem slot="buttonGroupItem">
          <ButtonField mode={'text'} size={'small'} iconPosition={'left'}>
            <div id="download-log-button">
              <button
                type="button"
                onClick={() => downloadLog(selectedOrchestration, downloadHook)}
                disabled={checkIfDisabled(selectedOrchestration?.status)}
                data-testid="DownloadErrorLogBtn"
              >
                <Iconsvg name="download-line"></Iconsvg>
                Download error log
              </button>
            </div>
          </ButtonField>
          <Tooltip
            position="bottom"
            targetSelector="#download-log-button"
            id="download-tooltip"
          >
            <TooltipBody slot="tooltipBody">Download error log</TooltipBody>
          </Tooltip>
        </ButtonGroupItem>

        <DropdownMenuItem
          onItemClick={() => openErrorLogModal()}
          slot="buttonGroupDropdownItem"
        >
          <Iconsvg name="visible"></Iconsvg>
          View error log
        </DropdownMenuItem>
        <DropdownMenuItem
          onItemClick={() => downloadLog(selectedOrchestration, downloadHook)}
          slot="buttonGroupDropdownItem"
        >
          <Iconsvg name="download-line"></Iconsvg>
          Download error log
        </DropdownMenuItem>
      </ButtonGroup>
    </CsbErrorBoundary>
  );
};

export default OrchestrationHeader;
