import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { IOrchestrationRow } from '../../interfaces/dashboard/orchestration-row.interface';
import style from './CellOrchestrationName.module.scss';
interface ICellOrchestrationName {
  data: IOrchestrationRow;
}

const CellOrchestrationName = ({ data }: ICellOrchestrationName) => {
  const rowData: IOrchestrationRow = data;
  return (
    <CsbErrorBoundary>
      {rowData?.product ? (
        <span>
          {rowData.name}/
          <span
            className={style['product-name']}
            data-testid="CellOrchestrationName"
          >
            {rowData.product.name}
          </span>
        </span>
      ) : (
        rowData?.name
      )}
    </CsbErrorBoundary>
  );
};

export default CellOrchestrationName;
