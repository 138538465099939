import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowClickedEvent,
} from 'ag-grid-community';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react/lib/agGridReact';
import { AgGridColumn } from 'ag-grid-react/lib/shared/agGridColumn';
import { useCallback, useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CellStatus from '../../CellStatus/CellStatus';
import CellLink from 'components/CellLink/CellLink';
import ErrorLogCellStatus from 'components/ErrorLogCellStatus/ErrorLogCellStatus';
import { ITableContainerProps } from 'interfaces/table.interface';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './RunOrchestrationTable.module.scss';
import { handleSort } from '../utils/eventsHandlers';
import DateTimeInput from 'components/DateTimeInput/DateTimeInput';
import TableOverlayComponent from 'components/TableOverlayComponent/TableOverlayComponent';
import CellWorkflowName from 'components/CellWorkflowName/CellWorkflowName';
import {
  selectRunOrchestrationLoading,
  setSelectedWorkflow,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import CellOptions from 'components/CellOptions/CellOptions';
import {
  IWorkflow,
  IWorkflowProduct,
} from 'interfaces/runOrchestration/workflow-row';
import { TABLE_FIELDS } from 'utils/common-constants';

const defaultColumnDef: ColDef = {
  width: 200,
  sortable: true,
  suppressColumnsToolPanel: true,
  icons: {
    sortAscending: '<span class="wk-icon-arrow-up" aria-hidden="true"/></span>',
    sortDescending:
      '<span class="wk-icon-arrow-down" aria-hidden="true"/></span>',
  },
};

const gridOptionComponents = {
  cellStatus: CellStatus,
  cellLink: CellLink,
  errorLogCellStatus: ErrorLogCellStatus,
  agDateInput: DateTimeInput,
  cellWorkflowName: CellWorkflowName,
  cellOptions: CellOptions,
};

const RunOrchestrationTable = (props: ITableContainerProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const gridApiRef = useRef<GridApi | null>(null);
  const gridColumnApiRef = useRef<ColumnApi | null>(null);
  const loading = useAppSelector(selectRunOrchestrationLoading);

  const getLoading = useCallback(() => {
    if (gridApiRef.current == null) {
      setTimeout(() => {
        getLoading();
      }, 100);
    } else {
      loading
        ? gridApiRef.current?.showLoadingOverlay()
        : gridApiRef.current?.hideOverlay();
    }
  }, [loading, gridApiRef]);

  useEffect(() => {
    gridApiRef?.current?.refreshCells({
      force: true,
      columns: [TABLE_FIELDS.name],
    });
  }, [props.items]);

  useEffect(() => {
    getLoading();
    adjustColumnsWidth();
  }, [getLoading, props.items]);

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;

    params.api.sizeColumnsToFit();
  };

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    gridApiRef.current?.sizeColumnsToFit();
  };

  const onSortChanged = (params: any) => {
    const sorted = params?.api?.columnModel?.displayedColumns?.find(
      (column: any) => column.sort != null
    );
    handleSort(
      sorted,
      undefined,
      dispatch,
      gridColumnApiRef,
      gridApiRef,
      props.page
    );
  };

  const adjustColumnsWidth = () => {
    setTimeout(() => {
      if (gridColumnApiRef) {
        gridColumnApiRef.current?.autoSizeAllColumns();
        gridApiRef.current?.sizeColumnsToFit();
      }
    }, 200);
  };

  const onRowClicked = (event: RowClickedEvent<any>) => {
    dispatch(setSelectedWorkflow(event.data as IWorkflow | IWorkflowProduct));
  };

  const gridOptions: GridOptions = {
    components: gridOptionComponents,
    rowSelection: 'multiple',
    suppressRowClickSelection: true,
    suppressColumnVirtualisation: true,
    localeText: {
      search: 'Start typing...',
    },
    rowMultiSelectWithClick: true,
    defaultColDef: defaultColumnDef,
    headerHeight: 40,
    rowHeight: 40,
    domLayout: 'autoHeight',
    loadingOverlayComponent: TableOverlayComponent,
    loadingOverlayComponentParams: { page: props.page },
    overlayNoRowsTemplate: '<span>No results</span>',
    animateRows: true,
    onGridReady,
    onGridSizeChanged,
    onSortChanged,
    onRowClicked,
  };

  return (
    <CsbErrorBoundary>
      <div className={style['csb-table']}>
        <div
          className={`${style['csb-table__container']} wk-advanced-table-container`}
          data-testid="TableContainer"
        >
          <div className={style['csb-table__top-border']}></div>
          <div
            className={`wk-advanced-table ${style['csb-table__content']} ${
              props.additionalStyles ?? style['wk-striped']
            }`}
          >
            <AgGridReact
              gridOptions={gridOptions}
              rowData={props.items}
              {...props.additionalData}
            >
              {props.columns.map((column) => (
                <AgGridColumn {...column} key={column.field} />
              ))}
            </AgGridReact>
          </div>
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

export default RunOrchestrationTable;
