import { STEP_STATUSES } from 'utils/common-constants';
import { useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'react-flow-renderer';
import {
  useTooltipStatusColor,
  useCompleteStatusColor,
} from 'utils/hooks/useStatusColor';
import { Tag, Tooltip, TooltipBody } from '@wk/components-react16';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './OrchestrationFlowElement.module.scss';
import { stepTypes } from 'interfaces/orchestrationDiagram/orchestration-diagram';

export interface OrchestrationFlowElementProps {
  data: {
    name?: string;
    moduleName?: string;
    duration?: string;
    status?: STEP_STATUSES;
    level?: number;
    description?: string;
    index?: string;
    version?: string;
    isConditional?: boolean;
    stepType?: stepTypes;
    id?: string;
  };
}

const OrchestrationFlowElement = (props: OrchestrationFlowElementProps) => {
  const {
    status = STEP_STATUSES.NOT_STARTED,
    name,
    moduleName,
    duration,
    description,
    index,
    version,
    isConditional,
    stepType,
    id,
  } = props.data;
  const statusColor = useCompleteStatusColor(status);
  const tagColor = useTooltipStatusColor(status);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const elementRef = useRef<any>();
  const [fontSize, setFontSize] = useState<string>('14px');
  const NAME_BY_STATUS: Record<STEP_STATUSES, any> = {
    [STEP_STATUSES.NOT_STARTED]: 'NOT STARTED',
    [STEP_STATUSES.IN_PROGRESS]: 'IN PROGRESS',
    [STEP_STATUSES.FAILED]: 'FAILED',
    [STEP_STATUSES.FINISHED]: 'FINISHED',
    [STEP_STATUSES.FINISHED_ERROR]: 'FINISHED WITH ERRORS',
    [STEP_STATUSES.QUEUED]: 'QUEUED',
  };

  useEffect(() => {
    if (name) {
      if (name.length > 40) {
        setFontSize('13px');
      }
      if (name.length > 80) {
        setFontSize('12px');
      }
    }
  }, [name]);

  const toggleZindex = (elementRef: any, position: 'top' | 'bottom') => {
    if (!elementRef.current?.parentElement) {
      return;
    }
    if (position === 'top') {
      elementRef.current.parentElement.style.cssText += `z-index: 100`;
    } else {
      elementRef.current.parentElement.style.cssText += `z-index: 0`;
    }
  };

  const openStepDetailsInPanel = () => {
    const stepInPropertiesPanel = document.querySelector(
      `#accordion-${index}`
    ) as HTMLElement;
    const accordionElement = stepInPropertiesPanel?.querySelector(
      '[aria-expanded]'
    ) as HTMLElement;
    if (accordionElement?.getAttribute('aria-expanded') === 'false') {
      accordionElement.click();
    }
    setTimeout(() => {
      stepInPropertiesPanel?.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }, 100);
  };

  return (
    <CsbErrorBoundary>
      <div
        className={style['orchestration-flow-element']}
        style={{
          borderColor: statusColor,
          fontSize,
        }}
        data-testid="OrchestrationFlowElement"
        id={`orchestration-flow-element-${id}`}
        ref={elementRef}
        key={`flow-element-${index}`}
        onClick={() => openStepDetailsInPanel()}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {(stepType === 'failback' || stepType === 'globalFailback') && (
          <div className={style['orchestration-flow-element__failback']}></div>
        )}
        <section>
          <div className={style['orchestration-flow-tag']}>
            {tagColor && (
              <Tag color={tagColor} mode="background">{`${
                stepType === 'globalFailback'
                  ? 'Global Failback '
                  : stepType === 'failback'
                  ? 'Failback '
                  : ''
              }${index} | ${NAME_BY_STATUS[status]}`}</Tag>
            )}
          </div>
          <div>
            <span>{name}</span>
            <span>{duration}</span>
          </div>
          <div>
            <Handle type="target" position={Position.Top} id="top" />
            <Handle type="source" position={Position.Bottom} id="bottom" />
          </div>
          {isConditional && (
            <div className={style['orchestration-flow-element__conditional']}>
              <span>IF</span>
            </div>
          )}
        </section>
        <Tooltip
          position="right"
          targetSelector={`#orchestration-flow-element-${id}`}
          id="orchestration-flow-tooltip"
          controlMode="controlled"
          isOpen={isHovered}
          onOpenBegin={() => toggleZindex(elementRef, 'top')}
          onCloseEnd={() => toggleZindex(elementRef, 'bottom')}
        >
          <TooltipBody slot="tooltipBody">
            <div
              className={style['tooltip-body']}
              data-testid="OrchestrationFlowTooltip"
            >
              <span>
                <p>CSB Module:</p> {moduleName}
              </span>
              <span>
                <p>Version: </p> {version}
              </span>
              <span>
                <p>Description:</p> {description}
              </span>
            </div>
          </TooltipBody>
        </Tooltip>
      </div>
    </CsbErrorBoundary>
  );
};

export default OrchestrationFlowElement;
