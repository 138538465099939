import {
  ButtonField,
  ButtonGroup,
  ButtonGroupItem,
  Iconsvg,
  Tooltip,
  TooltipBody,
} from '@wk/components-react16';
import style from './RunOrchestrationButtons.module.scss';
import { useTooltipControlled } from 'utils/hooks/useTooltipControlled';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { openModal, setSidebarNavigated } from 'app/modal/ModalSlice';
import { selectSelectedWorkflow } from 'app/runOrchestrations/RunOrchestrationsSlice';
import {
  IWorkflowProduct,
  RUN_ORCHESTRARIONS_STATUS,
} from 'interfaces/runOrchestration/workflow-row';
import { useSubmitWorkflowMutation } from 'api/orchestrationApi';
import {
  formatJobRequest,
  hasPermission,
  showToastAndClose,
} from 'utils/commonFunctions/CommonFunctions';
import { useNavigate } from 'react-router-dom';
import { SPINE_MENU_ITEMS, USER_PERMISSIONS } from 'utils/common-constants';
import { MODAL_NAMES } from 'components/Modals/modal-list';
import { selectUserPermissions } from 'app/auth/AuthSlice';
import {
  useImportWorkflowMutation,
  useLazyGetWorkflowsQuery,
} from 'api/workflows';
import { useRef } from 'react';

const IMPORT = 'import';
const CREATE_PRODUCT = 'create-product';
const CONFIG_RUN = 'config-run';
const RUN = 'run';

const buttonsData = [
  {
    id: IMPORT,
    text: 'Import an orchestration template',
  },
  {
    id: CREATE_PRODUCT,
    text: 'Create a product variant of your orchestration',
  },
  {
    id: CONFIG_RUN,
    text: 'Configure and submit your orchestration',
  },
  {
    id: RUN,
    text: 'Submit your orchestration with its default configuration',
  },
];

export interface RunOrchestrationButtonsProps {
  isDevOrAdmin: boolean | undefined;
}

export const RunOrchestrationButtons = ({
  isDevOrAdmin,
}: RunOrchestrationButtonsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [submitWokflow, { isLoading }] = useSubmitWorkflowMutation();
  const [importWorkflow] = useImportWorkflowMutation();
  const selectedOrchestration = useAppSelector(selectSelectedWorkflow);
  const { handleTooltip, getValue } = useTooltipControlled([
    IMPORT,
    CREATE_PRODUCT,
    CONFIG_RUN,
    RUN,
  ]);
  const userPermission = useAppSelector(selectUserPermissions);
  const fileInputRef = useRef<any>(null);

  const handleConfigureAndRun = () => {
    handleOpenModal(dispatch, MODAL_NAMES.CONFIGURE_AND_RUN);
  };

  const handleCreateProduct = () => {
    handleOpenModal(dispatch, MODAL_NAMES.CREATE_PRODUCT);
  };

  const handleRun = async () => {
    if (selectedOrchestration) {
      try {
        const jobRequest = formatJobRequest({
          name: selectedOrchestration.isProduct
            ? (selectedOrchestration as IWorkflowProduct).parentName
            : selectedOrchestration.name,
          productName: selectedOrchestration.isProduct
            ? (selectedOrchestration as IWorkflowProduct).name
            : undefined,
        });
        const responseId = await submitWokflow({
          ...jobRequest,
        }).unwrap();
        showToastAndClose(
          'success',
          'configureAndRunModal',
          (...args) => {},
          dispatch
        );
        navigate(`/dashboard/orchestration/${responseId}`);
        dispatch(setSidebarNavigated(SPINE_MENU_ITEMS.VIEW_ORCHESTRATION));
      } catch (error) {
        showToastAndClose(
          'error',
          'configureAndRunModal',
          (...args) => {},
          dispatch
        );
      }
    }
  };

  const handleImport = async (event: any) => {
    const file = event?.target?.files?.[0];
    if (file && file.name) {
      if (file.name.replace('.xml', '') !== selectedOrchestration?.name) {
        showToastAndClose('error', 'informationToast', () => {}, dispatch, {
          text: 'Orchestration template name does not match the selected orchestration name',
        });
        return;
      }
      try {
        const response: any = await importWorkflow(file);
        if (response?.data) {
          showToastAndClose('success', 'informationToast', () => {}, dispatch, {
            text: 'File imported successfully',
          });
        } else {
          showToastAndClose('error', 'informationToast', () => {}, dispatch, {
            text: `File upload error: ${response.error.data}`,
          });
        }
      } catch (error) {
        showToastAndClose('error', 'informationToast', () => {}, dispatch, {
          text: `File upload error: ${error}`,
        });
      }
    }
  };

  return (
    <div
      className={style['buttons']}
      data-testid="RunOrchestrationButtons"
      id="runOrchestrationButtons"
    >
      {isDevOrAdmin !== undefined && (
        <ButtonGroup mode="static">
          {isDevOrAdmin && (
            <ButtonGroupItem slot="buttonGroupItem">
              <ButtonField mode={'ghost'} iconPosition={'left'}>
                <button
                  type="button"
                  onClick={() => fileInputRef.current.click()}
                  id="import"
                  data-testid="ImportBtn"
                  onMouseEnter={() => handleTooltip(IMPORT, true)}
                  onMouseLeave={() => handleTooltip(IMPORT, false)}
                  disabled={checkIfDisabled(
                    IMPORT,
                    selectedOrchestration,
                    isLoading,
                    userPermission
                  )}
                >
                  <input
                    type="file"
                    data-testid="temp-input"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleImport}
                  />
                  <Iconsvg name="import"></Iconsvg>
                  Import
                </button>
              </ButtonField>
            </ButtonGroupItem>
          )}
          {isDevOrAdmin && (
            <ButtonGroupItem slot="buttonGroupItem">
              <ButtonField mode={'ghost'} iconPosition={'left'}>
                <button
                  type="button"
                  onClick={() => handleCreateProduct()}
                  id="create-product"
                  data-testid="CreateProductBtn"
                  onMouseEnter={() => handleTooltip(CREATE_PRODUCT, true)}
                  onMouseLeave={() => handleTooltip(CREATE_PRODUCT, false)}
                  disabled={checkIfDisabled(
                    CREATE_PRODUCT,
                    selectedOrchestration,
                    isLoading,
                    userPermission
                  )}
                >
                  <Iconsvg name="plus"></Iconsvg>
                  Create Product
                </button>
              </ButtonField>
            </ButtonGroupItem>
          )}
          <ButtonGroupItem slot="buttonGroupItem">
            <ButtonField iconPosition={'left'}>
              <button
                type="button"
                onClick={() => handleConfigureAndRun()}
                id="config-run"
                data-testid="ConfigureAndRunBtn"
                onMouseEnter={() => handleTooltip(CONFIG_RUN, true)}
                onMouseLeave={() => handleTooltip(CONFIG_RUN, false)}
                disabled={checkIfDisabled(
                  CONFIG_RUN,
                  selectedOrchestration,
                  isLoading,
                  userPermission
                )}
              >
                <Iconsvg name="play"></Iconsvg>
                Configure and run
              </button>
            </ButtonField>
          </ButtonGroupItem>
          <ButtonGroupItem slot="buttonGroupItem">
            <ButtonField iconPosition={'left'}>
              <button
                type="button"
                onClick={() => handleRun()}
                id="run"
                data-testid="runBtn"
                onMouseEnter={() => handleTooltip(RUN, true)}
                onMouseLeave={() => handleTooltip(RUN, false)}
                disabled={checkIfDisabled(
                  RUN,
                  selectedOrchestration,
                  isLoading,
                  userPermission
                )}
              >
                <Iconsvg name="play"></Iconsvg>
                Run
              </button>
            </ButtonField>
          </ButtonGroupItem>
        </ButtonGroup>
      )}
      <Tooltips isDevOrAdmin={isDevOrAdmin} getValue={getValue} />
    </div>
  );
};

const Tooltips = ({
  isDevOrAdmin,
  getValue,
}: {
  isDevOrAdmin: boolean | undefined;
  getValue: (value: string) => true | undefined;
}) => {
  return (
    <>
      {isDevOrAdmin !== undefined && (
        <>
          {buttonsData.map((item: any) => (
            <Tooltip
              position="bottom"
              targetSelector={`#${item.id}`}
              controlMode="controlled"
              isOpen={getValue(item.id)}
            >
              <TooltipBody slot="tooltipBody">{item.text}</TooltipBody>
            </Tooltip>
          ))}
        </>
      )}
    </>
  );
};

const checkIfDisabled = (
  buttonType: string,
  selectedOrchestration: any,
  isLoading: boolean,
  userPermission: any
) => {
  let isDisabled = false;

  if (buttonType === IMPORT) {
    isDisabled =
      !selectedOrchestration ||
      isLoading ||
      selectedOrchestration.isProduct ||
      !hasPermission(userPermission, [USER_PERMISSIONS.ORCHESTRATION_IMPORT]) ||
      selectedOrchestration.status === RUN_ORCHESTRARIONS_STATUS.INACTIVE;
  } else if (buttonType === CREATE_PRODUCT) {
    isDisabled =
      !selectedOrchestration ||
      isLoading ||
      selectedOrchestration.isProduct ||
      !hasPermission(userPermission, [USER_PERMISSIONS.PRODUCT_CREATE]);
  } else if (buttonType === CONFIG_RUN || buttonType === RUN) {
    isDisabled =
      !selectedOrchestration ||
      selectedOrchestration.status === RUN_ORCHESTRARIONS_STATUS.INACTIVE ||
      isLoading ||
      !hasPermission(userPermission, [USER_PERMISSIONS.ORCHESTRATION_SUBMIT]);
  }
  return isDisabled;
};

const handleOpenModal = (dispatch: any, name: MODAL_NAMES) => {
  const titles: Partial<Record<MODAL_NAMES, string>> = {
    [MODAL_NAMES.CONFIGURE_AND_RUN]: 'Upload files and configure orchestration',
    [MODAL_NAMES.CREATE_PRODUCT]: 'Create new product',
  };
  dispatch(
    openModal({
      id: '1',
      componentName: name,
      title: titles[name],
      footerName: 'cancel-next',
      display: true,
      componentProps: { data: { wizardStartingIndex: 0 } },
      width: '1500px',
    })
  );
};
