import { useGetUsersAllQuery, usePostJobsMutation } from 'api/orchestrationApi';
import { RootState } from 'app/store';
import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import StatusContainer from '../../components/StatusContainer/StatusContainer';
import TableActionsContainer from '../../components/TableActionsContainer/TableActionsContainer';
import TableActionsLeft from '../../components/TableActionsLeft/TableActionsLeft';
import TableActionsRight from '../../components/TableActionsRight/TableActionsRight';
import { TABLE_COLUMNS } from './Dashboard.constants';
import { IDashboardState } from 'interfaces/dashboard/dashboard-slice.interface';
import { GridApi } from 'ag-grid-enterprise';
import { ORCHESTRATION_STATUSES, SSE_STATUS } from 'utils/common-constants';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import style from './Dashboard.module.scss';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import {
  SelectFilterInfoType,
  selectFilterStatus,
  selectPaginationInfo,
} from 'app/dashboard/DashboardSlice';
import { PAGE_OPTIONS } from 'interfaces/table.interface';
import { history } from 'utils/common-constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLazyGetWorkflowsQuery } from 'api/workflows';
import DashboardTable from 'components/TableContainer/DashboardTable/DashboardTable';
import { selectConnectionStatus } from 'app/auth/AuthSlice';
import { getOrchestrationDuration } from 'utils/commonFunctions/CommonFunctions';

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const dashboardStatus: IDashboardState = useAppSelector(
    (state: RootState) => state.dashboardContainer
  );
  const filterInfo = useAppSelector<SelectFilterInfoType>(selectFilterStatus);
  const paginatorInfo = useAppSelector(selectPaginationInfo);
  (history.navigate as any) = useNavigate();
  (history.location as any) = useLocation();
  const [loadDashboardInfo] = usePostJobsMutation();
  const [loadWorkflows] = useLazyGetWorkflowsQuery();
  const connectionStatusState = useAppSelector(selectConnectionStatus);
  const CLOCK_ICON_LABEL = 'clock';
  let increaseTimeInterval: any = {};
  useGetUsersAllQuery({});

  useEffect(() => {
    if (connectionStatusState === SSE_STATUS.RESTORED) {
      getInfo(paginatorInfo.page);
    }
  }, [connectionStatusState]);

  const getInfo = useCallback(
    async (currentPage?: number) => {
      if (currentPage && currentPage > 1) {
        loadDashboardInfo({
          ...filterInfo,
          activePage: 1,
        });
      } else {
        loadDashboardInfo(filterInfo || {});
      }
      loadWorkflows(null);
    },
    [dispatch, filterInfo]
  );

  useEffect(() => {
    getInfo();
  }, [getInfo]);

  const updateDurationColumn = (
    gridApiRef: MutableRefObject<GridApi | null>,
    orchestrations: IOrchestrationRow[]
  ) => {
    Object.values(increaseTimeInterval).forEach((timeout: any) =>
      clearInterval(timeout)
    );
    orchestrations.forEach((orchestration) => {
      if (orchestration?.status === ORCHESTRATION_STATUSES.IN_PROGRESS) {
        const rowNode = gridApiRef.current?.getRowNode(
          orchestration.id.toString()
        );
        setTimeout(() => getOrchestrationDuration(orchestration, rowNode), 100);
        (function increaseTimeloop() {
          increaseTimeInterval[orchestration.id] = setInterval(() => {
            const rowInDocument = document.querySelectorAll(
              `[row-id="${rowNode?.data.id}"]`
            )?.[1];
            const rowInDocumentStatusIcon = rowInDocument
              ?.querySelector('[data-e2e="cg-icon"]')
              ?.querySelector('svg')
              ?.getAttribute('icon-name');

            if (
              !rowInDocument ||
              (rowInDocument && rowInDocumentStatusIcon !== CLOCK_ICON_LABEL)
            ) {
              clearInterval(increaseTimeInterval[orchestration.id]);
            } else {
              getOrchestrationDuration(orchestration, rowNode);
            }
          }, 1000);
        })();
      }
    });
  };

  const getRowId = useMemo(() => {
    return (params: any) => {
      return params.data.id;
    };
  }, []);

  return (
    <div data-testid="Dashboard" className={style['dashboard']}>
      <div className={style['dashboard-div-status']}>
        <CsbErrorBoundary>
          <StatusContainer
            items={dashboardStatus.statuses}
            selectedStatus={dashboardStatus.selectedStatus}
          />
        </CsbErrorBoundary>
      </div>

      <div className={style['dashboard-div-actions']}>
        <CsbErrorBoundary>
          <TableActionsContainer>
            {{
              left: <TableActionsLeft />,
              right: <TableActionsRight />,
            }}
          </TableActionsContainer>
        </CsbErrorBoundary>
      </div>
      <div className={style['dashboard-div-table']}>
        <CsbErrorBoundary>
          <DashboardTable
            columns={TABLE_COLUMNS}
            additionalData={{ getRowId }}
            onGridReadyAdditionalMethods={[
              { id: 'updateDurationColumn', method: updateDurationColumn },
            ]}
            isServerSideFiltering={true}
            page={PAGE_OPTIONS.orchestrations}
            additionalStyles={''}
          />
        </CsbErrorBoundary>
      </div>
    </div>
  );
};

export default Dashboard;
