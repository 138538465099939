import RunOrchestrationTable from 'components/TableContainer/RunOrchestrationTable/RunOrchestrationTable';
import {
  IWorkflow,
  IWorkflowProduct,
} from 'interfaces/runOrchestration/workflow-row';
import { ExtendedColDef, PAGE_OPTIONS } from 'interfaces/table.interface';
import style from './ColumnView.module.scss';
import FlowChartDetails from 'components/FlowChartDetails/FlowChartDetails';
import { useEffect, useRef, useState } from 'react';
import { IOrchestrationFullStep } from 'interfaces/orchestrationDiagram/orchestration-step';
import {
  generateEdges,
  getSteps,
  processOrchestration,
} from 'pages/OrchestrationDetails/reactFlow/reactFlowHelpers';
import { createOrchestrationInstanceSidePanelStep } from 'dto/orchestrationDiagram/orchestrationInstance';
import { useAppDispatch } from 'app/hooks';
import {
  IEdges,
  IOrchestrationInstancePanel,
  IOrchestrationNode,
} from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { setInitialPropertiesPanelValues } from 'app/propertiesPanel/PropertiesPanelSlice';
import OrchestrationPropertiesPanel from 'components/OrchestrationPropertiesPanel/OrchestrationPropertiesPanel';
import { useWorkflowsViews } from 'utils/hooks/useWorkflowsViews';
import { IServerWorkflow } from 'interfaces/runOrchestration/server-workflow';
import { PROPERTIES_PANEL_OPTIONS } from 'pages/RunOrchestration/RunOrchestrations.constants';

interface IColumnView {
  workflowsState: IWorkflow[] | IWorkflowProduct[];
  getRowId: (params: any) => any;
  tableColumns: ExtendedColDef[];
}

export const ColumnView = ({
  workflowsState,
  getRowId,
  tableColumns,
}: IColumnView) => {
  const [nodes, setNodes] = useState<IOrchestrationNode[]>([]);
  const [edges, setEdges] = useState<IEdges[]>([]);
  const [orchestrationInstance, setOrchestrationInstance] =
    useState<IOrchestrationInstancePanel>();
  const containerRef = useRef<HTMLDivElement>(null);
  const flowChartContainer = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const workflowToLoad: IServerWorkflow | undefined = useWorkflowsViews();

  useEffect(() => {
    if (workflowToLoad !== undefined) {
      setOrchestrationDetails(workflowToLoad);
    }
  }, [workflowToLoad]);

  const setOrchestrationDetails = (orchestration: IServerWorkflow) => {
    if (orchestration?.nextStepGroup) {
      let steps: IOrchestrationFullStep[][] = [];
      let globalFailbackSteps: any = [];
      const containerWidth =
        flowChartContainer.current?.getBoundingClientRect().width ?? 1000;
      setNodes([]);
      setEdges([]);
      const orchestrationInstanceStep =
        createOrchestrationInstanceSidePanelStep(orchestration);
      steps = getSteps(orchestration.nextStepGroup, steps, false, 'step');
      globalFailbackSteps = getSteps(
        orchestration.failbackStepGroup,
        globalFailbackSteps,
        false,
        'globalFailback'
      );
      const { nodes, orchestrarionSteps } = processOrchestration(
        orchestration,
        containerWidth - containerWidth * 0.25,
        [...steps, ...globalFailbackSteps]
      );
      const edges = generateEdges(steps);
      const failBackedges = generateEdges(globalFailbackSteps);
      setNodes(nodes);
      setOrchestrationInstance(orchestrationInstanceStep);
      setEdges([...edges, ...failBackedges]);
      dispatch(
        setInitialPropertiesPanelValues({
          orchestrationInstancePanelStep: orchestrationInstanceStep,
          propertiesPanelSteps: orchestrarionSteps,
        })
      );
    }
  };

  return (
    <div
      className={style['column-view']}
      ref={containerRef}
      data-testid="ColumnView"
    >
      <RunOrchestrationTable
        items={workflowsState ?? []}
        columns={tableColumns}
        onGridReadyAdditionalMethods={[]}
        additionalData={{ getRowId }}
        page={PAGE_OPTIONS.runOrchestrations}
        additionalStyles={'no-right-padding'}
      />
      <div
        className={style['flow-chart']}
        data-testid="FlowChartDetails"
        ref={flowChartContainer}
      >
        <FlowChartDetails
          nodes={nodes}
          edges={edges}
          repositionOnNewData={true}
        />
      </div>
      {orchestrationInstance && (
        <OrchestrationPropertiesPanel
          containerRef={containerRef}
          variation={PROPERTIES_PANEL_OPTIONS.RUN_ORCHESTRATION}
        />
      )}
    </div>
  );
};
