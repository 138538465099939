import {
  AccordionItem,
  AccordionItemBody,
  AccordionItemHeader,
} from '@wk/components-react16';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IStepProperties } from 'interfaces/orchestrationDiagram/orchestration-step';
import { IOrchestrationSidePanelSteps } from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { useEffect, useState } from 'react';
import {
  selectPropertiesPanel,
  switchOpenStatus,
} from 'app/propertiesPanel/PropertiesPanelSlice';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import TextFieldOrTextAreaSwitcher from 'components/TextFieldOrTextAreaSwitcher/TextFieldOrTextAreaSwitcher';
import style from './OrchestrationStepAccordion.module.scss';
import { STEP_STATUSES } from 'utils/common-constants';
import { PROPERTIES_PANEL_OPTIONS } from 'pages/RunOrchestration/RunOrchestrations.constants';

interface IOrchestrationStepAccordion {
  variation?: PROPERTIES_PANEL_OPTIONS | null;
}

export const OrchestrationStepAccordion = ({
  variation,
}: IOrchestrationStepAccordion) => {
  const orchestrationSteps: IOrchestrationSidePanelSteps[] = useAppSelector(
    (state) => selectPropertiesPanel(state)
  );
  const [sortedSteps, setSortedSteps] = useState<
    IOrchestrationSidePanelSteps[]
  >([...orchestrationSteps]);
  const ACCORDION_SUBSECTION = 'accordion-subsection';
  const ACCORDION_SUBTITLE = 'accordion-subtitle';
  const dispatch = useAppDispatch();

  useEffect(() => {
    const temp = [...orchestrationSteps]?.sort((a, b) => {
      if (Number(a.index) - Number(b.index) < 0) {
        return -1;
      }
      if (Number(a.index) - Number(b.index) > 0) {
        return 1;
      }
      if (Number.isNaN(Number(a.index)) || Number.isNaN(Number(b.index))) {
        return 0;
      }
      return 0;
    });
    setSortedSteps(temp);
  }, [orchestrationSteps]);

  const StepPropertiesBlock = (
    step: IOrchestrationSidePanelSteps,
    isDefault: boolean
  ) => {
    const properties: IStepProperties[] = isDefault
      ? step.defaultStepProperties
      : step.stepProperties;
    return (
      <CsbErrorBoundary>
        <article className={style[ACCORDION_SUBSECTION]}>
          <span
            className={style[ACCORDION_SUBTITLE]}
            data-testid={`StepPropertiesLabel-${step.index}`}
          >
            {isDefault ? 'Default Step properties' : 'Step properties'}
          </span>
          {properties?.map((property) => (
            <TextFieldOrTextAreaSwitcher
              label={property?.paramName}
              id={property?.jobStepParamId.toString()}
              value={property?.paramValue}
              isSourcedFromBu={property?.isBuSource}
              key={property.paramName}
            />
          ))}
        </article>
      </CsbErrorBoundary>
    );
  };

  return (
    <CsbErrorBoundary>
      <>
        {sortedSteps?.map((step, index) => (
          <div
            key={step.index}
            id={`accordion-${step.index}`}
            className={`${style['orchestration-step-accordion']} ${
              step.isOpen ? style['accordion-wk-open'] : ''
            }`}
          >
            <AccordionItem
              isOpen={step.isOpen}
              key={`step-accordion-${step.index}`}
              isScrollbarEnabled={false}
              onUserRequest={(event: boolean) =>
                dispatch(
                  switchOpenStatus({ id: step.id, swtichInstance: false })
                )
              }
            >
              <AccordionItemHeader slot="accordionItemHeader">
                <p
                  className={style['accordion-header-title']}
                  data-testid={`OrchestrationStepHeaderNum${index}`}
                >{`${step.index}  ${step?.name}`}</p>
              </AccordionItemHeader>
              <AccordionItemBody slot="accordionItemBody">
                <section className={style['accordion-body']}>
                  <article className={style[ACCORDION_SUBSECTION]}>
                    <span className={style[ACCORDION_SUBTITLE]}>
                      Step information
                    </span>
                    <TextFieldOrTextAreaSwitcher
                      label="Step name"
                      id="stepName"
                      value={step?.name}
                    />
                    {step?.information?.retryNumber && (
                      <TextFieldOrTextAreaSwitcher
                        label="Retry Number"
                        id="stepRetryNumber"
                        value={step?.information.retryNumber?.toString()}
                      />
                    )}
                    {step?.information?.retryDelay && (
                      <TextFieldOrTextAreaSwitcher
                        label="Retry Delay"
                        id="stepRetryDelay"
                        value={step?.information.retryDelay?.toString()}
                      />
                    )}
                    {step?.information?.retryDelayMultiplier && (
                      <TextFieldOrTextAreaSwitcher
                        label="Retry Delay Multiplier"
                        id="stepRetryDelayMultiplier"
                        value={step?.information.retryDelayMultiplier?.toString()}
                      />
                    )}
                    {step?.stepCondition && (
                      <TextFieldOrTextAreaSwitcher
                        label="Step condition"
                        id="stepName"
                        value={step?.stepCondition}
                      />
                    )}
                    {variation !==
                      PROPERTIES_PANEL_OPTIONS.RUN_ORCHESTRATION && (
                      <>
                        <TextFieldOrTextAreaSwitcher
                          label="Status"
                          id="stepStatus"
                          value={
                            step?.information?.status ??
                            STEP_STATUSES.NOT_STARTED
                          }
                        />
                        <TextFieldOrTextAreaSwitcher
                          label="Duration"
                          id="stepDuration"
                          value={step?.information?.duration}
                        />
                        <TextFieldOrTextAreaSwitcher
                          label="Start time"
                          id="stepStartTime"
                          value={step?.information?.startTime?.toLocaleString()}
                        />
                        <TextFieldOrTextAreaSwitcher
                          label="End time"
                          id="stepEndTime"
                          value={step?.information?.endTime?.toLocaleString()}
                        />
                        <TextFieldOrTextAreaSwitcher
                          label="Executed number of times"
                          id="stepExecutedCount"
                          value={step?.information?.executionNumber?.toString()}
                        />
                      </>
                    )}
                  </article>
                  {step.stepProperties?.length > 0
                    ? StepPropertiesBlock(step, false)
                    : step?.defaultStepProperties?.length > 0 &&
                      StepPropertiesBlock(step, true)}
                  <article className={style[ACCORDION_SUBSECTION]}>
                    <span className={style[ACCORDION_SUBTITLE]}>
                      Module information
                    </span>
                    <TextFieldOrTextAreaSwitcher
                      label="Module name"
                      id="moduleName"
                      value={step?.moduleName}
                    />
                  </article>
                  <article className={style[ACCORDION_SUBSECTION]}>
                    <span className={style[ACCORDION_SUBTITLE]}>
                      Module properties
                    </span>
                    <TextFieldOrTextAreaSwitcher
                      label="Module version number"
                      id="moduleVersion"
                      value={step?.moduleVersion}
                    />
                    <TextFieldOrTextAreaSwitcher
                      label="Module description"
                      id="moduleDescription"
                      value={step?.moduleDescription}
                    />
                  </article>
                </section>
              </AccordionItemBody>
            </AccordionItem>
          </div>
        ))}
      </>
    </CsbErrorBoundary>
  );
};
