import {
  ColumnApi,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  RowDataUpdatedEvent,
  RowSelectedEvent,
} from 'ag-grid-community';
import { useLazyGetWorkflowByIdQuery } from 'api/orchestrationApi';
import {
  selectFilterModel,
  selectFilterStatus,
  selectPaginationInfo,
} from 'app/dashboard/DashboardSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import {
  handleFilter,
  handleRowDataUpdated,
  handleRowSelection,
  handleSort,
} from '../utils/eventsHandlers';
import {
  setNameFilterPopupWidth,
  setNameFilterValues,
  setUserFilterValues,
} from '../utils/filterUtils';
import { IJobStartedByUser } from 'interfaces/user.interface';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import {
  IWorkflow,
  IWorkflowProduct,
} from 'interfaces/runOrchestration/workflow-row';
import { ORCHESTRATION_STATUSES, TABLE_FIELDS } from 'utils/common-constants';
import {
  IJobStep,
  IUpdateJobStatus,
} from 'interfaces/dashboard/dashboard-slice.interface';
import {
  updateJobStatusInTable,
  updateJobStepsInTable,
} from 'dto/jobToTable/jobToTable';
import { getOrchestrationDuration } from 'utils/commonFunctions/CommonFunctions';

export const useDashboardTableEvents = (props: any) => {
  const dispatch = useAppDispatch();
  const gridApiRef = useRef<GridApi | null>(null);
  const gridColumnApiRef = useRef<ColumnApi | null>(null);
  const { activeFilters: activeFiltersState, allUsers } = useAppSelector(
    (state) => state.dashboardContainer
  );
  const filtersState = useAppSelector(selectFilterStatus);
  const paginatorInfo = useAppSelector(selectPaginationInfo);
  const filterModelState = useAppSelector(selectFilterModel);
  const activeFilters = useRef(activeFiltersState);
  const filterModel = useRef(filterModelState);
  const filterInfo: any = useRef(filtersState);
  const openFilterId: any = useRef();
  const filterDomElement: any = useRef(null);
  const [getWorkflowById] = useLazyGetWorkflowByIdQuery();

  const usersRef = useUserFilterSetup(allUsers, gridApiRef);

  const onGridReady = (params: GridReadyEvent) => {
    gridApiRef.current = params.api;
    gridColumnApiRef.current = params.columnApi;

    params.api.sizeColumnsToFit();
  };

  const onRowDataUpdated = (event: RowDataUpdatedEvent) => {
    handleRowDataUpdated(
      event,
      gridColumnApiRef,
      gridApiRef,
      activeFilters,
      props.onGridReadyAdditionalMethods
    );
  };

  const onRowSelected = async (event: RowSelectedEvent) => {
    handleRowSelection(event, getWorkflowById, dispatch);
  };

  const onGridSizeChanged = (event: GridSizeChangedEvent) => {
    gridApiRef.current?.sizeColumnsToFit();
  };

  const onFilterOpened = (event: any) => {
    filterDomElement.current = document.querySelector(
      '.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child'
    );
    if (filterDomElement?.current) {
      const id = event.column.colId;
      openFilterId.current = id;
      setNameFilterPopupWidth(id);
    }
  };

  const onFilterChanged = (event: FilterChangedEvent) => {
    handleFilter(
      event,
      filterModel,
      props.isServerSideFiltering,
      filterInfo.current,
      usersRef,
      dispatch,
      paginatorInfo
    );
  };

  const onSortChanged = (params: any) => {
    const sorted = params?.api?.columnModel?.displayedColumns?.find(
      (column: any) => column.sort != null
    );
    handleSort(
      sorted,
      filterInfo.current,
      dispatch,
      gridColumnApiRef,
      gridApiRef,
      props.page
    );
  };

  const memoizedHandlers = useMemo(
    () => ({
      onGridReady,
      onRowDataUpdated,
      onRowSelected,
      onGridSizeChanged,
      onFilterOpened,
      onFilterChanged,
      onSortChanged,
    }),
    [
      onGridReady,
      onRowDataUpdated,
      onRowSelected,
      onGridSizeChanged,
      onFilterOpened,
      onFilterChanged,
      onSortChanged,
    ]
  );

  return {
    gridApiRef,
    gridColumnApiRef,
    filtersState,
    paginatorInfo,
    filterModelState,
    activeFilters,
    filterModel,
    filterInfo,
    openFilterId,
    filterDomElement,
    ...memoizedHandlers,
  };
};

const useUserFilterSetup = (
  allUsers: IJobStartedByUser[] | undefined,
  gridApi: MutableRefObject<GridApi<any> | null>
) => {
  const usersRef = useRef<IJobStartedByUser[]>([]);

  useEffect(() => {
    if (allUsers?.length) {
      setUserFilterValues(gridApi.current, allUsers);
      usersRef.current = allUsers;
    }
  }, [allUsers, gridApi]);

  return usersRef;
};

export const useOrchestrationStateUpdate = (
  orchestrations: IOrchestrationRow[],
  isOrchestrationFiltered: MutableRefObject<boolean>,
  isLoadingNewPage: MutableRefObject<boolean>
) => {
  const [orchestrationsState, setOrchestrationState] =
    useState<IOrchestrationRow[]>(orchestrations);

  useEffect(() => {
    if (isOrchestrationFiltered.current === true) {
      setOrchestrationState((prevOrchestrationsState: any) => {
        const uniqueIds = new Set(
          prevOrchestrationsState.map((item: any) => item.id)
        );
        const filteredOrchestrations = orchestrations.filter(
          (item: any) => !uniqueIds.has(item.id)
        );
        return [...prevOrchestrationsState, ...filteredOrchestrations];
      });
    } else {
      setOrchestrationState(orchestrations);
      isOrchestrationFiltered.current = true;
    }
    isLoadingNewPage.current = false;
  }, [orchestrations, isOrchestrationFiltered, isLoadingNewPage]);

  return {
    orchestrationsState,
    setOrchestrationState,
  };
};

export const useWorkflowUpdate = (
  gridApi: MutableRefObject<GridApi<any> | null>,
  filterModel: any,
  workflows: IWorkflow[] | IWorkflowProduct[]
) => {
  useEffect(() => {
    gridApi?.current?.refreshCells({
      force: true,
      columns: [TABLE_FIELDS.name],
    });
    setNameFilterValues(gridApi.current, workflows);
    if (filterModel?.current) {
      gridApi?.current?.setFilterModel(filterModel.current);
    }
  }, [gridApi, filterModel, workflows]);
};

export function useHandleOrchestrationEvents(
  sseOrchestrationsEvents: any[],
  setOrchestrationState: any,
  orchestrationsState: IOrchestrationRow[],
  gridApiRef: MutableRefObject<GridApi<any> | null>,
  dispatch: any,
  modifySseOrchestrationEvents: any
) {
  useEffect(() => {
    if (sseOrchestrationsEvents?.length > 0) {
      const firstItem = [...sseOrchestrationsEvents].shift();
      if (firstItem) {
        if (firstItem?.type === 'addOrchestration') {
          insertItem(firstItem.data);
          dispatch(
            modifySseOrchestrationEvents([...sseOrchestrationsEvents].slice(1))
          );
        } else if (
          firstItem.type === 'updateStatus' ||
          firstItem.type === 'updateStep'
        ) {
          updateOrchestration(firstItem.data, firstItem.type);
          dispatch(
            modifySseOrchestrationEvents([...sseOrchestrationsEvents].slice(1))
          );
        }
      }
    }
  }, [
    sseOrchestrationsEvents,
    setOrchestrationState,
    orchestrationsState,
    gridApiRef,
    dispatch,
    modifySseOrchestrationEvents,
  ]);

  function insertItem(newItem: IOrchestrationRow) {
    if (newItem) {
      setOrchestrationState((orchestrationsState: IOrchestrationRow) =>
        [newItem].concat(orchestrationsState)
      );
    }
  }

  function updateOrchestration(
    updatedOrchestration: IUpdateJobStatus | IJobStep,
    type: 'updateStatus' | 'updateStep'
  ) {
    const foundOrchestrationIndex = orchestrationsState?.findIndex(
      (orchestration) => orchestration.id === updatedOrchestration.jobId
    );
    if (foundOrchestrationIndex !== -1) {
      const foundOrchestration = orchestrationsState[foundOrchestrationIndex];
      let updatedItem: IOrchestrationRow;
      if (type === 'updateStatus') {
        updatedItem = updateJobStatusInTable(
          foundOrchestration,
          updatedOrchestration as IUpdateJobStatus
        );
      } else {
        updatedItem = updateJobStepsInTable(
          foundOrchestration,
          updatedOrchestration as IJobStep
        );
      }
      const newOrchestrations: IOrchestrationRow[] = orchestrationsState?.map(
        (orchestration) => {
          return {
            ...orchestration,
            duration:
              orchestration.status === ORCHESTRATION_STATUSES.IN_PROGRESS
                ? getOrchestrationDuration(orchestration)
                : orchestration.duration,
          };
        }
      );
      newOrchestrations[foundOrchestrationIndex] = updatedItem;
      setOrchestrationState(
        (orchestrationsState: IOrchestrationRow) => newOrchestrations
      );
      const rowNode = gridApiRef.current?.getRowNode(updatedItem.id.toString());
      rowNode?.setData({ ...updatedItem });
    }
    return orchestrationsState;
  }
}
