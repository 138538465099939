import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import OrchestrationFlowElement from 'components/OrchestrationFlowElement/OrchestrationFlowElement';
import { useEffect, useMemo } from 'react';
import ReactFlow, {
  Controls,
  ReactFlowProvider,
  useReactFlow,
} from 'react-flow-renderer';
import style from './FlowChartDetails.module.scss';

interface FlowChartDetailsProps {
  nodes?: any;
  edges?: any;
  repositionOnNewData?: boolean;
}

const nodeTypes = {
  orchestrationFlowElement: OrchestrationFlowElement,
};

const FlowChartContainer = ({
  nodes,
  edges,
  repositionOnNewData,
}: FlowChartDetailsProps) => {
  const { setViewport } = useReactFlow();

  useEffect(() => {
    if (repositionOnNewData) {
      setViewport({ x: 100, y: 0, zoom: 1 }, { duration: 0 });
    }
  }, [nodes]);

  return (
    <CsbErrorBoundary>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        preventScrolling={false}
        panOnScroll={true}
        className={style['react-flow__chart-details']}
      >
        <Controls showInteractive={false} />
      </ReactFlow>
    </CsbErrorBoundary>
  );
};

const FlowChartDetails = ({
  nodes,
  edges,
  repositionOnNewData,
}: FlowChartDetailsProps) => (
  <ReactFlowProvider>
    <FlowChartContainer
      nodes={nodes}
      edges={edges}
      repositionOnNewData={repositionOnNewData}
    />
  </ReactFlowProvider>
);

export default FlowChartDetails;
