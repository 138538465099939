import { FC, MutableRefObject, useState } from 'react';
import { Accordion, SplitpaneAside } from '@wk/components-react16';
import { OrchestrationInstanceAccordion } from 'components/OrchestrationAccordions/OrchestrationInstanceAccordion/OrchestrationInstanceAccordion';
import { OrchestrationStepAccordion } from 'components/OrchestrationAccordions/OrchestrationStepAccordion/OrchestrationStepAccordion';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './OrchestrationPropertiesPanel.module.scss';
import { PROPERTIES_PANEL_OPTIONS } from 'pages/RunOrchestration/RunOrchestrations.constants';

interface OrchestrationPropertiesPanelProps {
  containerRef: MutableRefObject<any>;
  variation?: PROPERTIES_PANEL_OPTIONS | null;
}

const OrchestrationPropertiesPanel: FC<OrchestrationPropertiesPanelProps> = ({
  containerRef,
  variation,
}) => {
  const INITIAL_WIDTH_RUN_ORCHESTRATION = 60;
  const INITIAL_WIDTH = 25;
  const MIN_WIDTH = 20;
  const MAX_WIDTH = variation === 'run_orchestration' ? 100 : 60;
  const [width, setWidth] = useState<string>(
    variation === 'run_orchestration'
      ? `${INITIAL_WIDTH_RUN_ORCHESTRATION}%`
      : `${INITIAL_WIDTH}%`
  );
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const onToggle = () => setIsOpen((isOpen) => !isOpen);

  const onWidthChange = (mouseX: number) => {
    const container = containerRef.current;
    const { left, width } = container.getBoundingClientRect();
    let withinContainer = 0;
    let widthPercentLeft = 0;
    if (variation === 'run_orchestration') {
      withinContainer =
        mouseX - (left + width * (INITIAL_WIDTH_RUN_ORCHESTRATION / 100));
      widthPercentLeft =
        (withinContainer /
          (width - width * (INITIAL_WIDTH_RUN_ORCHESTRATION / 100))) *
        100;
    } else {
      withinContainer = mouseX - left;
      widthPercentLeft = (withinContainer / width) * 100;
    }
    const widthPercentRight = 100 - widthPercentLeft;
    const clamped = Math.min(MAX_WIDTH, Math.max(widthPercentRight, MIN_WIDTH));
    setWidth(clamped + '%');
  };

  return (
    <CsbErrorBoundary>
      <SplitpaneAside
        position={'right'}
        isOpen={isOpen}
        controlMode="controlled"
        widthControlMode="controlled"
        width={width}
        onUserRequest={onToggle}
        onWidthChange={(event: number) => onWidthChange(event)}
      >
        {isOpen ? (
          <div
            data-testid="OrchestrationPropertiesPanel"
            className={style['orchestration-properties-panel']}
          >
            <div className={style['orchestration-properties-panel__header']}>
              <span
                className={
                  style['orchestration-properties-panel__header-title']
                }
              >
                Details
              </span>
              <hr
                className={
                  style['orchestration-properties-panel__header-divider']
                }
              ></hr>
            </div>
            <div className={style['orchestration-properties-panel__accordion']}>
              <Accordion>
                <OrchestrationInstanceAccordion variation={variation} />
                <OrchestrationStepAccordion variation={variation} />
              </Accordion>
            </div>
          </div>
        ) : null}
      </SplitpaneAside>
    </CsbErrorBoundary>
  );
};

export default OrchestrationPropertiesPanel;
