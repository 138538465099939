import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import style from './RunOrchestrationBanner.module.scss';
import { SearchField } from '@wk/components-react16/dist/search-field/search-field';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Iconsvg } from '@wk/components-react16/dist/iconsvg/iconsvg';
import { SplitViewIcon } from 'components/CustomIcons/SplitViewIcon';
import { ColumnViewIcon } from 'components/CustomIcons/ColumnViewIcon';
import {
  ToggleField,
  ToggleFieldGroup,
  Tooltip,
  TooltipBody,
  WkSwitch,
  debounce,
} from '@wk/components-react16';
import { useTooltipControlled } from 'utils/hooks/useTooltipControlled';
import { selectUserRole } from 'app/auth/AuthSlice';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { USERS_ROLES } from 'utils/common-constants';
import { RunOrchestrationButtons } from './RunOrchestrationButtons/RunOrchestrationButtons';
import {
  searchFilter,
  selectFilterQuery,
  toggleFilteringOnlyActive,
} from 'app/runOrchestrations/RunOrchestrationsSlice';
import { VIEW_OPTIONS } from 'pages/RunOrchestration/RunOrchestrations.constants';

interface IRunOrchestrationBanner {
  setCurrentView: (args: VIEW_OPTIONS) => void;
}
export const RunOrchestrationBanner = ({
  setCurrentView,
}: IRunOrchestrationBanner) => {
  const [switchStatus, setSwitchStatus] = useState(false);
  const [toggleGroupStatus, setToggleGroupStatus] = useState<number>(0);
  const userRole = useAppSelector(selectUserRole);
  const [isDevOrAdmin, setIsDevOrAdmin] = useState(undefined);
  const stateFilerQuery = useAppSelector(selectFilterQuery);
  const searchInput = useRef<HTMLInputElement | any>();
  const dispatch = useAppDispatch();
  const { handleTooltip, getValue } = useTooltipControlled([
    'switch',
    VIEW_OPTIONS.LIST_VIEW,
    VIEW_OPTIONS.SPLIT_VIEW,
    VIEW_OPTIONS.COLUMN_VIEW,
  ]);

  const handleSwitch = () => {
    setSwitchStatus((switchStatus) => !switchStatus);
    dispatch(toggleFilteringOnlyActive());
  };

  const viewsData = [
    { id: VIEW_OPTIONS.LIST_VIEW, text: 'Display list view' },
    { id: VIEW_OPTIONS.SPLIT_VIEW, text: 'Display split view' },
    { id: VIEW_OPTIONS.COLUMN_VIEW, text: 'Display column view' },
  ];

  useEffect(() => {
    setIsDevOrAdmin(
      userRole.includes(USERS_ROLES.DEVELOPER) ||
        userRole.includes(USERS_ROLES.ADMIN)
    );
  }, [userRole]);

  const handleViewChange = (status: VIEW_OPTIONS) => {
    setCurrentView(status);
    switch (status) {
      case VIEW_OPTIONS.LIST_VIEW:
        setToggleGroupStatus(0);
        break;
      case VIEW_OPTIONS.SPLIT_VIEW:
        setToggleGroupStatus(1);
        break;
      case VIEW_OPTIONS.COLUMN_VIEW:
        setToggleGroupStatus(2);
        break;
      default:
        break;
    }
  };

  // Clear input if searchFilter(null) is called
  useEffect(() => {
    debounce((stateFilerQuery: any) => {
      if (
        stateFilerQuery === null &&
        searchInput.current &&
        searchInput.current.value !== ''
      ) {
        searchInput.current.value = '';
      }
    }, 500)();
  }, [stateFilerQuery]);

  const handleSearch = debounce((searchParam: string) => {
    if (stateFilerQuery !== searchParam) {
      if (searchParam !== '' && searchParam != null) {
        dispatch(searchFilter(searchParam));
      } else {
        dispatch(searchFilter(null));
      }
    }
  }, 500);

  return (
    <CsbErrorBoundary>
      <div
        className={style['banner-container']}
        data-testid="RunOrchestrationBanner"
      >
        <p className={style['title-row']}>Run Orchestration</p>
        <div className={style['toolbar-row']}>
          <div className={style['search-bar']}>
            <SearchField>
              <input
                type="search"
                id="search"
                name="search"
                data-testid="SearchInput"
                placeholder={`Search for an orchestration`}
                ref={searchInput}
                onChange={(event) => handleSearch(event.target.value)}
              />
            </SearchField>
          </div>
          <div
            className={style['toogle']}
            id="my-orchestrations-toggle"
            data-testid="OrchestrationToogle"
            onMouseEnter={() => handleTooltip('switch', true)}
            onMouseLeave={() => handleTooltip('switch', false)}
          >
            <WkSwitch
              name="activeWorkflowsSwtich"
              isChecked={switchStatus}
              label="View only Active"
              size="small"
              onChange={() => handleSwitch()}
            ></WkSwitch>
            <Tooltip
              position="bottom"
              targetSelector="#my-orchestrations-toggle"
              controlMode="controlled"
              isOpen={getValue('switch')}
            >
              <TooltipBody slot="tooltipBody">
                {switchStatus
                  ? 'View your active orchestrations only'
                  : 'View all orchestration'}
              </TooltipBody>
            </Tooltip>
          </div>
          <div className={style['view-status']}>
            <span>View</span>
            <ToggleFieldGroup srLabel={'Views toogle'}>
              {viewsData.map((view, i) => (
                <ToggleElements
                  handleViewChange={handleViewChange}
                  handleTooltip={handleTooltip}
                  viewOption={view.id}
                  toggleGroupStatus={toggleGroupStatus}
                  index={i}
                  key={view.id}
                />
              ))}
            </ToggleFieldGroup>
            {viewsData.map((item: any) => (
              <Tooltip
                position="bottom"
                targetSelector={`#${item.id}`}
                controlMode="controlled"
                isOpen={getValue(item.id)}
                key={item.id}
              >
                <TooltipBody slot="tooltipBody">{item.text}</TooltipBody>
              </Tooltip>
            ))}
          </div>
          <RunOrchestrationButtons isDevOrAdmin={isDevOrAdmin} />
        </div>
      </div>
    </CsbErrorBoundary>
  );
};

interface IToggleElements {
  handleViewChange: (status: VIEW_OPTIONS) => any;
  handleTooltip: (viewOption: VIEW_OPTIONS, display: boolean) => any;
  viewOption: VIEW_OPTIONS;
  toggleGroupStatus: number;
  index: number;
}
const ToggleElements = ({
  handleViewChange,
  handleTooltip,
  viewOption,
  toggleGroupStatus,
  index,
}: IToggleElements) => {
  const data = {
    [VIEW_OPTIONS.LIST_VIEW]: {
      dataTestid: 'ToggleList',
      id: 'listView',
      htmlFor: 'toggle-list',
      ariaLabel: 'Toggle list',
      icon: <Iconsvg name="list-bullet-dot"></Iconsvg>,
    },
    [VIEW_OPTIONS.SPLIT_VIEW]: {
      dataTestid: 'ToggleSplit',
      id: 'splitView',
      htmlFor: 'toggle-grid',
      ariaLabel: 'Toggle grid',
      icon: (
        <Iconsvg>
          <SplitViewIcon
            stroke={toggleGroupStatus === 1 ? '#005B92' : undefined}
          />
        </Iconsvg>
      ),
    },
    [VIEW_OPTIONS.COLUMN_VIEW]: {
      dataTestid: 'ToggleColumn',
      id: 'columnView',
      htmlFor: 'toggle-column',
      ariaLabel: 'Toggle column',
      icon: (
        <Iconsvg>
          <ColumnViewIcon
            stroke={toggleGroupStatus === index ? '#005B92' : undefined}
          />
        </Iconsvg>
      ),
    },
  };
  return (
    <ToggleField>
      <div
        className={style['custom-icon']}
        onClick={() => handleViewChange(viewOption)}
        data-testid={data[viewOption].dataTestid}
        id={data[viewOption].id}
        onMouseEnter={() => handleTooltip(viewOption, true)}
        onMouseLeave={() => handleTooltip(viewOption, false)}
      >
        <label
          htmlFor={data[viewOption].htmlFor}
          aria-label={data[viewOption].ariaLabel}
        >
          <input
            type="radio"
            name="toggle-radio"
            id={data[viewOption].htmlFor}
            defaultChecked={toggleGroupStatus === index}
          />
          {data[viewOption].icon}
        </label>
      </div>
    </ToggleField>
  );
};
