import { STEP_STATUSES } from 'utils/common-constants';
import { mapOrchestrationStep } from 'dto/orchestrationDiagram/orchestrationSteps';
import {
  IOrchestration,
  IOrchestrationFullStep,
  IOrchestrationStepGroup,
  IStepInformation,
} from 'interfaces/orchestrationDiagram/orchestration-step';
import {
  IEdges,
  IOrchestrationNode,
  IOrchestrationSidePanelSteps,
  stepTypes,
} from 'interfaces/orchestrationDiagram/orchestration-diagram';
import { DateUtils } from 'utils/dateUtils/DateUtils';
import { Useuid } from 'utils/hooks/useUid';
import { IServerWorkflow } from 'interfaces/runOrchestration/server-workflow';

const X_GAP = 50;
const NODE_WIDTH = 300 + X_GAP;
const NODE_HEIGHT = 80;
const Y_GAP = 30;

//get initial steps and tag them with necesary properties
export const getSteps = (
  stepsGroup: any,
  acc: IOrchestrationFullStep[][],
  isNestedStep: boolean,
  stepType: stepTypes,
  nestGroupId?: any
): IOrchestrationFullStep[][] => {
  if (stepsGroup) {
    const newStep = stepsGroup.steps?.map((step: any) => ({
      ...step,
      isNestedStep,
      stepType,
      nestGroupId:
        step.nestGroupId?.length > 0
          ? [...step.nestGroupId, nestGroupId].flat()
          : [nestGroupId].flat(),
    }));
    acc.push(newStep);
    getNestedAndFailbackSteps(newStep, acc, isNestedStep, stepType);
    getSteps(
      stepsGroup.nextStepGroup,
      acc,
      isNestedStep,
      stepType,
      nestGroupId
    );
  }
  return acc;
};

const getNestedAndFailbackSteps = (
  newStep: any,
  acc: IOrchestrationFullStep[][],
  isNestedStep: boolean,
  stepType: stepTypes
) => {
  newStep?.map((step: any) => {
    const nestedGroupId =
      step.nestGroupId?.length > 0 &&
      step.nestGroupId.find((groupId: string) => groupId !== undefined)
        ? [...step.nestGroupId, step.workflowStepId.toString()]
        : [step.workflowStepId.toString()];
    if (step.failbackStepGroup) {
      getSteps(
        step.failbackStepGroup,
        acc,
        isNestedStep,
        stepType === 'globalFailback' ? stepType : 'failback',
        nestedGroupId
      );
    }
    if (step.nestedStepGroup) {
      getSteps(step.nestedStepGroup, acc, true, stepType, nestedGroupId);
    }
  });
};

export const getStepsWithGroups = (workflow: any) => {
  const result: any = [];

  // Helper function to recursively traverse the steps and groups
  const traverseSteps = (stepGroup: any) => {
    if (!stepGroup) {
      return;
    }

    for (const step of stepGroup.steps) {
      const stepInfo = {
        stepTitle: step.stepTitle,
        workflowStepId: step.workflowStepId,
        stepIndex: step.stepIndex,
        workflowStepGroupId: step.workflowStepGroupId,
        childs: [
          step.nestedStepGroup?.steps?.map((x: any) => ({
            workflowStepId: x.workflowStepId,
            stepIndex: x.stepIndex,
            stepTitle: x.stepTitle,
            workflowStepGroupId: x.workflowStepGroupId,
          })),
          stepGroup.nextStepGroup?.steps?.map((x: any) => ({
            workflowStepId: x.workflowStepId,
            stepIndex: x.stepIndex,
            stepTitle: x.stepTitle,
            workflowStepGroupId: x.workflowStepGroupId,
          })),
          step.failbackStepGroup?.steps?.map((x: any) => ({
            workflowStepId: x.workflowStepId,
            stepIndex: x.stepIndex,
            stepTitle: x.stepTitle,
            workflowStepGroupId: x.workflowStepGroupId,
          })),
        ]
          .filter((x) => x !== undefined)
          .flat(),
      };
      result.push(stepInfo);

      // Recursively traverse nested step groups
      traverseSteps(step.nestedStepGroup);

      // Recursively traverse failback step groups
      traverseSteps(step.failbackStepGroup);
    }

    // Recursively traverse next step groups
    traverseSteps(stepGroup.nextStepGroup);
  };

  traverseSteps(workflow.nextStepGroup);

  return result;
};

export function processOrchestration(
  orchestration: Partial<IOrchestration> | Partial<IServerWorkflow>,
  containerWidth = 1000,
  steps: IOrchestrationFullStep[][]
): {
  nodes: IOrchestrationNode[];
  orchestrarionSteps: IOrchestrationSidePanelSteps[];
} {
  if (!steps || !steps.length) {
    return { nodes: [], orchestrarionSteps: [] };
  }
  let stepsParamsData: any = undefined;

  if ('steps' in orchestration) {
    stepsParamsData = getStepsParamsData(orchestration.steps);
  }
  const stepsSideBarData = generateStepsSideBarData(steps, stepsParamsData);

  const orchestrationContext = {
    currentY: 0,
    nodeTracker: {},
    processedNodes: [],
    stepsParamsData,
  };

  processWorkflowSteps(orchestration, orchestrationContext, containerWidth);
  return {
    nodes: orchestrationContext.processedNodes,
    orchestrarionSteps: stepsSideBarData,
  };
}

function processWorkflowSteps(
  orchestration: Partial<IOrchestration> | Partial<IServerWorkflow> | any,
  context: any,
  containerWidth: number
) {
  const { processIndividualStepGroup } =
    initializeStepProcessingFunctions(context);

  if (orchestration.workflow?.nextStepGroup || orchestration.nextStepGroup) {
    const workflow =
      orchestration.workflow?.nextStepGroup ?? orchestration.nextStepGroup;
    processIndividualStepGroup(workflow, containerWidth / 2 - 150, 0, 'step');
  }
  if (
    orchestration.workflow?.failbackStepGroup ||
    orchestration.failbackStepGroup
  ) {
    const workflow =
      orchestration.workflow?.failbackStepGroup ??
      orchestration.failbackStepGroup;
    processIndividualStepGroup(
      workflow,
      containerWidth / 2 - 150,
      context.currentY + NODE_HEIGHT + Y_GAP,
      'globalFailback'
    );
  }
}

function initializeStepProcessingFunctions(context: any) {
  function calculateGroupWidth(stepGroup: any): number {
    if (!stepGroup || !stepGroup.steps) {
      return 0;
    }
    const width = stepGroup.steps.length * NODE_WIDTH;
    const nestedWidths = stepGroup.steps.map((step: any) => {
      const nested = step.nestedStepGroup
        ? calculateGroupWidth(step.nestedStepGroup)
        : 0;
      const failback = step.failbackStepGroup
        ? calculateGroupWidth(step.failbackStepGroup)
        : 0;
      return Math.max(nested, failback);
    });
    return width + Math.max(...nestedWidths);
  }

  function calculateParentNodeXPosition(
    currentX: number,
    nestedEndX: number,
    failbackEndX: number
  ): number {
    const minChildX = currentX;
    const maxChildX = Math.max(nestedEndX, failbackEndX);
    return (minChildX + maxChildX) / 2 - NODE_WIDTH / 2;
  }

  function calculateStepXPosition(
    currentX: number,
    step: any,
    stepGroup: any
  ): number {
    const childGroupWidth = calculateGroupWidth(step.nestedStepGroup);
    const failbackGroupWidth = calculateGroupWidth(step.failbackStepGroup);
    if (childGroupWidth > NODE_WIDTH || failbackGroupWidth > NODE_WIDTH) {
      const inmidiateChilds =
        (step.failbackStepGroup?.steps?.length || 0) +
        (step.nestedStepGroup?.steps?.length || 0);
      return currentX + (NODE_WIDTH * inmidiateChilds) / 2 - NODE_WIDTH / 2;
    } else {
      return (
        currentX +
        Math.max(childGroupWidth, failbackGroupWidth) /
          (stepGroup.steps.length + (stepGroup.steps.length > 1 ? 1 : 0))
      );
    }
  }

  function processIndividualStepGroup(
    stepGroup: IOrchestrationStepGroup,
    startX: number,
    startY: number,
    stepType: stepTypes
  ): number {
    if (!stepGroup || !stepGroup.steps) {
      return startX;
    }

    let currentX = startX;
    context.currentY = startY;
    let prevTotalWidth = 0;

    const updatedPositioning = processAndPositionSteps(
      stepGroup,
      prevTotalWidth,
      currentX,
      startY,
      stepType
    );
    currentX = updatedPositioning.currentX;
    prevTotalWidth = updatedPositioning.updatedPrevTotalWidth;

    if (stepGroup.nextStepGroup) {
      const groupWidth = calculateGroupWidth(stepGroup.nextStepGroup);
      const localGroupWidth = stepGroup.steps.length;
      let newX = startX;
      if (groupWidth > NODE_WIDTH) {
        newX = startX + (currentX - startX - groupWidth) / groupWidth;
      }
      if (localGroupWidth > 1) {
        currentX = currentX - prevTotalWidth;
        newX = (startX + currentX) / 2;
      }
      if (stepGroup.nextStepGroup.steps?.length > 1) {
        newX =
          newX - (NODE_WIDTH * (stepGroup.nextStepGroup.steps?.length - 1)) / 2;
      }
      processIndividualStepGroup(
        stepGroup.nextStepGroup,
        newX,
        context.currentY + NODE_HEIGHT + Y_GAP,
        stepType === 'globalFailback' ? stepType : 'step'
      );
    }

    return currentX;
  }

  const processAndPositionSteps = (
    stepGroup: any,
    prevTotalWidth: number,
    currentX: number,
    startY: number,
    stepType: stepTypes
  ): any => {
    let updatedPrevTotalWidth = prevTotalWidth;
    for (const step of stepGroup.steps) {
      if (context.nodeTracker[step.workflowStepId]) {
        continue;
      }

      const childGroupWidth = calculateGroupWidth(step.nestedStepGroup);
      const failbackGroupWidth = calculateGroupWidth(step.failbackStepGroup);
      const totalWidth = Math.max(
        childGroupWidth,
        failbackGroupWidth,
        NODE_WIDTH
      );
      updatedPrevTotalWidth = totalWidth;

      let stepX = calculateStepXPosition(currentX, step, stepGroup);
      context.nodeTracker[step.workflowStepId] = true;

      const nestedEndX = processIndividualStepGroup(
        step.nestedStepGroup,
        currentX,
        startY + NODE_HEIGHT + Y_GAP,
        stepType === 'globalFailback' ? stepType : 'step'
      );

      const failbackEndX = processIndividualStepGroup(
        step.failbackStepGroup,
        currentX,
        startY + NODE_HEIGHT + Y_GAP,
        stepType === 'globalFailback' ? stepType : 'failback'
      );

      if (
        step?.nestedStepGroup?.steps?.length === 1 ||
        step?.failbackStepGroup?.steps?.length === 1
      ) {
        stepX = calculateParentNodeXPosition(
          currentX,
          nestedEndX,
          failbackEndX
        );
      }
      const stepParamsData = context.stepsParamsData?.[step.workflowStepId];
      context.processedNodes.push({
        id: step.workflowStepId.toString(),
        type: 'orchestrationFlowElement',
        data: {
          name: step.stepTitle || '',
          id: step.workflowStepId.toString(),
          moduleName: step.module?.name,
          description: `${step.module?.description}`,
          duration: getNodeDuration(stepParamsData),
          status: stepParamsData?.status,
          index: step.stepIndex,
          stepType,
          version: step.module?.version,
          isConditional:
            step.stepCondition != null && step.stepCondition !== ''
              ? true
              : false,
        },
        position: { x: stepX, y: startY },
      });
      currentX += totalWidth;
    }
    return { currentX, updatedPrevTotalWidth };
  };
  return { processIndividualStepGroup };
}

const getNodeDuration = (stepParamsData: any) => {
  return stepParamsData?.status === STEP_STATUSES.NOT_STARTED
    ? '-'
    : DateUtils.getFormattedDiff(
        stepParamsData?.startTime,
        stepParamsData?.endTime || new Date().toISOString()
      ) || '1s';
};

const getStepsParamsData = (
  steps: IStepInformation[] | undefined
): { [key: number]: IStepInformation } | undefined => {
  if (steps === undefined || steps === null) {
    return undefined;
  }
  const stepsObject: { [key: number]: IStepInformation } = {};
  steps.forEach((step: any) => {
    stepsObject[step.workflowStepId] = step;
  });
  return stepsObject;
};

const generateStepsSideBarData = (
  steps: IOrchestrationFullStep[][],
  stepsObject: any[] | undefined
): any[] => {
  const stepsSideBarData: any = [];
  steps.forEach((stepGroup: IOrchestrationFullStep[]) =>
    stepGroup.forEach((step: IOrchestrationFullStep) =>
      stepsSideBarData.push(
        mapOrchestrationStep(step, stepsObject?.[step.workflowStepId])
      )
    )
  );
  return stepsSideBarData;
};

export const generateEdges = (
  stepGroups: IOrchestrationFullStep[][]
): IEdges[] => {
  const edges: IEdges[][] = [];
  stepGroups.forEach((group, index) => {
    group.forEach((currentStep: IOrchestrationFullStep) => {
      if (!currentStep) {
        return;
      }
      const nextFailBackGroup = currentStep.failbackStepGroup?.steps;
      const nextNestedGroup = currentStep.nestedStepGroup?.steps;
      const nextGroup = findNextGroup(stepGroups, index, currentStep);

      if (nextFailBackGroup?.length) {
        edges.push(
          setEdgesBasedOnStepGroup(nextFailBackGroup, currentStep, index, false)
        );
      } else if (nextNestedGroup?.length) {
        edges.push(
          setEdgesBasedOnStepGroup(nextNestedGroup, currentStep, index, false)
        );
      } else if (nextGroup?.length) {
        edges.push(
          setEdgesBasedOnStepGroup(nextGroup, currentStep, index, true)
        );
      }
    });
  });
  return edges.flat();
};

const findNextGroup = (
  stepGroups: IOrchestrationFullStep[][],
  index: number,
  currentStep: IOrchestrationFullStep
): IOrchestrationFullStep[] | undefined => {
  return stepGroups.slice(index + 1).find((steps) => {
    return steps.some((step) => {
      const nestGroupIds = [...(step.nestGroupId || [])].reverse();
      return (
        !step.isNestedStep ||
        nestGroupIds.some((groupId) => {
          return (
            groupId &&
            currentStep.nestGroupId?.includes(groupId) &&
            currentStep.workflowStepId > step.workflowStepId &&
            Number(currentStep.stepIndex) < Number(step.stepIndex)
          );
        })
      );
    });
  });
};

export function setEdgesBasedOnStepGroup(
  stepsGroup: IOrchestrationFullStep[],
  currentStep: IOrchestrationFullStep,
  stepIndex: number,
  avoidNested: boolean
): IEdges[] {
  return stepsGroup
    .map((step: IOrchestrationFullStep) => {
      if (
        avoidNested === true &&
        step.isNestedStep === true &&
        step.failbackStepGroup !== null
      ) {
        return null;
      } else if (
        currentStep.workflowStepId?.toString() !==
        step.workflowStepId?.toString()
      ) {
        return {
          id: `edge-${Useuid()}`,
          source: currentStep.workflowStepId?.toString(),
          target: step.workflowStepId?.toString(),
          sourceHandle: 'bottom',
          type: 'step',
          className: 'csb-custom-edge',
        } as IEdges;
      } else {
        return null;
      }
    })
    .filter((edge) => edge != null) as IEdges[];
}
