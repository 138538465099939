import {
  AvatarInitials,
  Banner,
  BannerLogo,
  BannerRight,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  Fishbowl,
} from '@wk/components-react16';
import '@wk/components-react16/dist/all.min.css';
import { usePostLogoutMutation } from 'api/authApi';
import CsbErrorBoundary from 'components/CsbErrorBoudary/CsbErrorBoundary';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';
import style from './HeaderCsb.module.scss';

const HeaderCsb = () => {
  const userInfo = useAppSelector((state: RootState) => state.auth.user);
  const [logout] = usePostLogoutMutation();

  const logEvent = (event: any) => {
    console.log(event);
  };

  const logoutHandler = () => {
    logout(null);
  };

  const getInitials = (name = 'G'): string => {
    const parts = name.split('@')[0].split('.');
    return parts.map((n) => n[0]).join('');
  };

  return (
    <CsbErrorBoundary>
      <Banner
        mode="workflow"
        productName="Apollo CSB Dashboard"
        onProductNameClick={() => logEvent('Apollo CSB Dashboard')}
      >
        <BannerLogo
          title="Go to Wolters Kluwer Apollo CSB Dashboard home page"
          alt="Go to Wolters Kluwer Apollo CSB Dashboard home page"
          isPreventDefault
          onLogoClick={() => logEvent('logo')}
          slot="bannerLogo"
        ></BannerLogo>
        <BannerRight slot="bannerRight">
          <div
            id="fishbowlWrapper"
            data-testid="HeaderCsb"
            className={style['fishbowlWrapper']}
          >
            <Fishbowl
              tooltipPlacementOptions={{ positions: ['left'] }}
              userName={userInfo?.email || ''}
              organizationName={userInfo?.userRole?.name}
              organizationInitials={userInfo?.userRole?.businessUnit}
              onFishbowlClick={() => logEvent('fishbowl')}
            >
              <AvatarInitials
                initials={getInitials(userInfo?.email)}
              ></AvatarInitials>
            </Fishbowl>
          </div>
          <Dropdown
            targetSelector="#fishbowlWrapper"
            placementOptions={{ positions: ['bottom-left'] }}
          >
            <DropdownMenu>
              <DropdownMenuItem onItemClick={() => logoutHandler()}>
                Logout
              </DropdownMenuItem>
            </DropdownMenu>
          </Dropdown>
        </BannerRight>
      </Banner>
    </CsbErrorBoundary>
  );
};

export default HeaderCsb;
