import {
  DateFilterModel,
  GridApi,
  ISetFilter,
  RowNode,
} from 'ag-grid-community';
import { NumberFilterModel, SetFilterModel } from 'ag-grid-enterprise';
import { ORCHESTRATION_STATUSES } from 'utils/common-constants';
import saveAs from 'file-saver';
import { IJobStep } from 'interfaces/dashboard/dashboard-slice.interface';
import { IOrchestrationRow } from 'interfaces/dashboard/orchestration-row.interface';
import { DateUtils } from '../dateUtils/DateUtils';
import { addToast } from 'app/toast/toastSlice';
import { Useuid } from 'utils/hooks/useUid';
import {
  IToastAdditionalData,
  ToastStatus,
  ToastTypes,
} from 'interfaces/toasts';
import { MODAL_ACTIONS } from 'interfaces/modals/close-modal-info.interface';
import {
  IPreSubmitWorkflow,
  ISubmitWorkflow,
} from 'interfaces/modals/configure-and-run.interface';
import { format, utcToZonedTime } from 'date-fns-tz';

export const getNameFromEmail = (
  email: string,
  defaultName = 'Automated process'
) => {
  return email && !email.startsWith('local.csb.user')
    ? email
        .split('@')[0]
        .split('.')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
    : defaultName;
};

export const downloadLog = async (
  orchestration: IOrchestrationRow,
  downloadHook: any
) => {
  try {
    const response = await downloadHook({
      CSBJobId: orchestration.id,
      fromTimeCSB: orchestration.originalStartTime,
    }).unwrap();
    const blob = new Blob([response['data']], {
      type: response['contentType'],
    });
    const fileName = response['name'];
    saveAs(blob, fileName);
  } catch (error) {
    consoleErrorMessage(error);
  }
};

export function capitalizeFirstLetter(str: any) {
  if (typeof str !== 'string') {
    return str;
  }
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
}

export const calculateJobDurationIfApplies = (
  job: any | IOrchestrationRow
): string => {
  if (job.status === ORCHESTRATION_STATUSES.PENDING) {
    return 'Pending';
  }
  if (job.status === ORCHESTRATION_STATUSES.STOPPING) {
    return 'Stopping';
  }
  if (job.status === ORCHESTRATION_STATUSES.STOPPED) {
    return 'Stopped early';
  }
  if (job.startTime && !job.endTime) {
    const endTime = new Date(Date.parse(job.startTime) + 10).toISOString();
    // return DateUtils.getFormattedDiff(job.startTime, endTime) || '<1s';
    // return getOrchestrationDuration(job);
    return job.duration;
  }
  if (job.startTime && job.endTime) {
    return DateUtils.getFormattedDiff(job.startTime, job.endTime) || '<1s';
  } else {
    return 'Pending';
  }
};

export const getApiRoute = (route: string) => {
  return `${process.env.REACT_APP_API_URL}/${route}`;
};

export const currentSetTimeout = (
  current: React.MutableRefObject<NodeJS.Timeout>,
  callbackFn: any,
  time: number
) => {
  current.current = setTimeout(callbackFn, time);
};

export const currentClearTimeout = (
  current: React.MutableRefObject<NodeJS.Timeout>
) => {
  clearTimeout(current.current);
};

export const isPropertyToBeMasked = (prop: string) => {
  const maskedNames = ['Password', 'Key'];
  return maskedNames.some((name) => prop.includes(name));
};

export const maskValue = (value: string) => {
  return value.replace(/./g, '*');
};

export const updateSteps = (currentOrchestration: any, newStep: IJobStep) => {
  const updatedSteps = currentOrchestration?.steps?.map((step: IJobStep) => {
    if (step.workflowStepId.toString() === newStep.workflowStepId.toString()) {
      return {
        ...step,
        status: newStep.status,
        startTime: newStep.startTime,
        endTime: newStep.endTime === undefined ? step.endTime : newStep.endTime,
        jobStepParams:
          newStep.jobStepParams === undefined
            ? step.jobStepParams
            : newStep.jobStepParams,
      };
    } else {
      return step;
    }
  });
  return {
    ...currentOrchestration,
    steps: updatedSteps,
  };
};

export const isObjectsAreEqual = (obj1: any, obj2: any) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const consoleErrorMessage = (error: any) => {
  const message =
    (error.response && error.response.data && error.response.data.message) ||
    error.message ||
    error.toString();
  console.error(message);
};

export function createFilename(files: File[]) {
  let fileName = '';
  for (let i = 0; i < files.length; i++) {
    fileName += files[i].name;
    if (i < files.length - 1) {
      fileName += ', ';
    }
  }
  return fileName;
}

export const showToastAndClose = (
  status: ToastStatus,
  type: ToastTypes,
  handleUserRequest: (...args: any) => void,
  dispatch: (...args: any) => void,
  additionalData?: IToastAdditionalData
) => {
  dispatch(
    addToast({
      id: Useuid(),
      status,
      type,
      additionalData,
    })
  );
  handleUserRequest(MODAL_ACTIONS.cancel);
};

export const combineStatus = (
  status: ORCHESTRATION_STATUSES
): ORCHESTRATION_STATUSES => {
  if (status === ORCHESTRATION_STATUSES.STOPPING) {
    return ORCHESTRATION_STATUSES.STOPPED;
  }
  if (status === ORCHESTRATION_STATUSES.FINISHED_ERROR) {
    return ORCHESTRATION_STATUSES.FINISHED;
  }

  return status;
};

export const formatJobRequest = (data: IPreSubmitWorkflow): ISubmitWorkflow => {
  if (!data) {
    return {} as any;
  }
  let jobRequest: ISubmitWorkflow = {
    name: data.name,
    productName: data.productName,
    data: {
      priority: data.priority ?? '',
    },
  };
  if (data.content && data.content?.length > 0) {
    jobRequest = {
      ...jobRequest,
      data: { ...jobRequest.data, content: data.content as any },
    };
  }
  if (data.parameters?.length && data.parameters?.length > 0) {
    jobRequest = getParamsFromJobRequest(jobRequest, data);
  } else {
    jobRequest = {
      ...jobRequest,
      data: { ...jobRequest.data, parameters: {} },
    };
  }
  if (
    data.s3content &&
    data.s3content.files &&
    data.s3content.files.length > 0
  ) {
    jobRequest = {
      ...jobRequest,
      data: { ...jobRequest.data, s3content: data.s3content },
    };
  }
  return jobRequest;
};

const getParamsFromJobRequest = (
  jobRequest: ISubmitWorkflow,
  data: IPreSubmitWorkflow
) => {
  const newParams = data.parameters.map((param: any) => {
    if (param && typeof param === 'object') {
      const filteredParam = Object.fromEntries(
        Object.entries(param).filter(([key, val]) => val !== '' && val !== null)
      );
      return Object.keys(filteredParam).length > 0 ? filteredParam : null;
    }
    return null;
  });
  jobRequest = {
    ...jobRequest,
    data: {
      ...jobRequest.data,
      parameters: newParams.reduce((acc: any, param: any, index: number) => {
        if (param !== null) {
          acc[index] = param;
        }
        return acc;
      }, {}),
    },
  };
  return jobRequest;
};

export const AddZeroPrefix = (number: number | string) => {
  return number
    ? !isNaN(Number(number)) && Number(number) < 10
      ? `0${number}`
      : number
    : '';
};

export const hasPermission = (
  userPermissions: any,
  requiredPermission: string[]
) => {
  if (!userPermissions) {
    return false;
  }
  for (const [permission, value] of Object.entries(userPermissions)) {
    if (
      requiredPermission.includes(permission) &&
      (value === 'Enable' || value === 'Allow')
    ) {
      return true;
    }
  }
  return false;
};

export const safeStringify = (obj: any) => {
  try {
    return JSON.stringify(obj);
  } catch (error) {
    console.error('Error while stringifying:', error);
    return '';
  }
};

export const safeParse = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    console.error('Error while parsing:', error);
    return null;
  }
};

export const getOrchestrationDuration = (
  orchestration: IOrchestrationRow,
  rowNode?: RowNode
) => {
  const utcTimeNow = format(
    utcToZonedTime(new Date(), 'UTC'),
    'M/d/yyyy, HH:mm:ss'
  );
  const returnedTime = DateUtils.getFormattedDiff(
    orchestration.startTime,
    utcTimeNow,
    'd-h-m-s'
  );
  if (returnedTime && rowNode) {
    rowNode?.setData({ ...rowNode.data, duration: returnedTime });
  }
  return returnedTime;
};
