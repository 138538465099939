import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IJobStep } from 'interfaces/dashboard/dashboard-slice.interface';
import {
  IJob,
  IJobStatus,
  IPropertiesPanel,
  ISwitchOpenStatus,
} from 'interfaces/orchestrationDiagram/properties-panel.interface';
import {
  combineStatus,
  updateSteps,
} from 'utils/commonFunctions/CommonFunctions';
import { RootState } from '../store';
import { ORCHESTRATION_STATUSES } from 'utils/common-constants';

export const SLICE_KEY = 'propertiesPanel';

const initialState: IPropertiesPanel = {
  orchestrationInstancePanelStep: undefined,
  propertiesPanelSteps: [],
  currentJob: undefined,
};

const propertiesPanelSlice = createSlice({
  name: SLICE_KEY,
  initialState,
  reducers: {
    setInitialPropertiesPanelValues: (
      state,
      { payload }: PayloadAction<IPropertiesPanel>
    ) => {
      state.orchestrationInstancePanelStep =
        payload.orchestrationInstancePanelStep;
      state.propertiesPanelSteps = payload.propertiesPanelSteps;
    },
    setCurrentJob: (state, { payload }: PayloadAction<IJob>) => {
      state.currentJob = payload;
    },
    updateCurrentJobSteps: (state, { payload }: PayloadAction<IJobStep>) => {
      if (payload?.jobId && state.currentJob?.jobId === payload.jobId) {
        return {
          ...state,
          currentJob: updateSteps(state.currentJob, payload),
        };
      }
      return { ...state };
    },
    switchOpenStatus: (
      state,
      { payload }: PayloadAction<ISwitchOpenStatus>
    ) => {
      if (
        payload.swtichInstance === true &&
        state.orchestrationInstancePanelStep
      ) {
        state.orchestrationInstancePanelStep.isOpen =
          !state.orchestrationInstancePanelStep.isOpen;
      } else {
        const index = state.propertiesPanelSteps.findIndex(
          (p) => p.id === payload.id
        );
        state.propertiesPanelSteps[index].isOpen =
          !state.propertiesPanelSteps[index].isOpen;
      }
    },
    updateCurrentJobStatus: (state, { payload }: PayloadAction<IJobStatus>) => {
      if (payload.jobId === state.currentJob.jobId) {
        return {
          ...state,
          currentJob: {
            ...state.currentJob,
            status: combineStatus(payload.status as ORCHESTRATION_STATUSES),
            startTime: payload.startTime ?? state.currentJob?.startTime,
            endTime: payload.endTime ?? state.currentJob?.endTime,
          },
        };
      } else {
        return {
          ...state,
        };
      }
    },
  },
});

export const selectIndividualPanelStep = (state: RootState, id: string) =>
  state.propertiesPanel?.propertiesPanelSteps?.find((e) => e.id === id);

export const selectPropertiesPanel = (state: RootState) =>
  state.propertiesPanel?.propertiesPanelSteps;

export const selectOrchetsrationInstanceStep = (state: RootState) =>
  state.propertiesPanel?.orchestrationInstancePanelStep;

export const selectCurrentJob = (state: RootState) =>
  state.propertiesPanel?.currentJob;

export const {
  switchOpenStatus,
  setCurrentJob,
  updateCurrentJobSteps,
  setInitialPropertiesPanelValues,
  updateCurrentJobStatus,
} = propertiesPanelSlice.actions;

export default propertiesPanelSlice.reducer;
