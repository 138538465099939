import { TABLE_FIELDS } from 'utils/common-constants';
import { ExtendedColDef } from 'interfaces/table.interface';

export const RUN_ORCHESTRATIONS_TABLE_COLUMNS: ExtendedColDef[] = [
  {
    headerName: 'Orchestration Name',
    field: TABLE_FIELDS.name,
    menuTabs: [],
    minWidth: 330,
    maxWidth: 330,
    cellRenderer: 'cellWorkflowName',
    cellClass: 'csb-visible-tooltip',
  },
  {
    headerName: 'Orchestration Description',
    field: TABLE_FIELDS.description,
    minWidth: 400,
    menuTabs: [],
    suppressSizeToFit: false,
  },
  {
    headerName: 'Worker',
    field: TABLE_FIELDS.worker,
    minWidth: 150,
    maxWidth: 150,
    menuTabs: [],
    suppressSizeToFit: false,
  },
  {
    headerName: 'Throttle Rate',
    field: TABLE_FIELDS.throttleRate,
    minWidth: 140,
    maxWidth: 140,
    menuTabs: [],
    filter: 'agNumberColumnFilter',
    suppressSizeToFit: false,
  },
  {
    headerName: 'Status',
    field: TABLE_FIELDS.status,
    minWidth: 80,
    maxWidth: 80,
    menuTabs: [],
    suppressSizeToFit: false,
  },
  {
    headerName: '',
    field: TABLE_FIELDS.options,
    cellClass: 'csb-visible-cell',
    cellRenderer: 'cellOptions',
    suppressSizeToFit: false,
    minWidth: 60,
    maxWidth: 60,
    menuTabs: [],
  },
];

export enum VIEW_OPTIONS {
  LIST_VIEW = 'listView',
  SPLIT_VIEW = 'splitView',
  COLUMN_VIEW = 'columnView',
}

export enum PROPERTIES_PANEL_OPTIONS {
  RUN_ORCHESTRATION = 'run_orchestration',
}
